import React, { useContext, useState, useEffect } from "react";
import "./CategorySelector.css";
import { BudgetContext } from "../../context/BudgetContext";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import firebase from "firebase";
import Popper from "@mui/material/Popper";
import { Paper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import SplitCategory from "./SplitCategory";

function CategorySelector(props) {
  const {
    transaction,
    budgetAmountMonth,
    updateTransactionCategoryId,
    setLoading
  } = props;
  const budgetData = useContext(BudgetContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOption, setCurrentOption] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [splitObject, setSplitOject] = useState([]);
  const [showSplitCategory, setShowSplitCategory] = useState(false);

  useEffect(() => {
    var optionArray = [];
    optionArray.push({ name: `Transfers`, isParent: true, fbTransfers: true });
    optionArray.push(...budgetData.parentCategories);
    setParentCategories(optionArray);

    const categoryID = transaction.userCategoryId;

    var childOptions = [];

    const accountId = window.location.pathname.split("/")[2];
    const accounts = budgetData.accounts.filter(
      account => account.id !== accountId
    );

    accounts.forEach(item => {
      childOptions.push({
        name: `Transfer To ${item.accountName}`,
        id: item.id,
        fbTransfer: true
      });
    });

    if (typeof categoryID == "string") {
      const filteredCategory = budgetData.childCategories
        .concat(childOptions)
        .filter(child => child.id === categoryID);
      setCurrentOption(filteredCategory[0].name);
    } else {
      if (typeof categoryID === "object" && categoryID !== null) {
        const objectToDisplay = [];
        categoryID.forEach((item, index) => {
          const name = budgetData.childCategories
            .concat(childOptions)
            .filter(child => child.id === item.id);

          objectToDisplay.push({
            name: name[0].name,
            amount: item.amount
          });

          if (index + 1 === categoryID.length) {
            setSplitOject(objectToDisplay);
          }
        });

        let filteredCategories = budgetData.childCategories
          .concat(childOptions)
          .filter(child => categoryID.indexOf(child.id) > -1);

        filteredCategories = filteredCategories.map(item => item.name);

        // Loop through and find each one...
      } else {
        setCurrentOption("No Category");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.userCategoryId]);

  // Takes in the parent item to populate the children for the selector.
  const getChildren = item => {
    if (searchTerm) {
      const filteredCategories = budgetData.childCategories.filter(
        child =>
          child.parentId === item.id &&
          child.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const filteredTransferAccounts = getTransferAccounts(item).filter(
        child =>
          child.parentId === item.id &&
          child.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return filteredCategories.concat(filteredTransferAccounts);
    } else {
      const filteredCategories = budgetData.childCategories.filter(
        child => child.parentId === item.id
      );
      return filteredCategories.concat(getTransferAccounts(item));
    }
  };

  const getTransferAccounts = item => {
    // If the parent item is the one we're adding above.
    if (item.fbTransfers) {
      var optionArray = [];
      const accountId = window.location.pathname.split("/")[2];
      const accounts = budgetData.accounts.filter(
        account => account.id !== accountId
      );

      accounts.forEach(item => {
        optionArray.push({
          name: `Transfer To ${item.accountName}`,
          id: item.id,
          fbTransfer: true
        });
      });

      return optionArray;
    } else {
      return [];
    }
  };

  const updateCategory = value => {
    var optionArray = [];

    const accountId = window.location.pathname.split("/")[2];
    const accounts = budgetData.accounts.filter(
      account => account.id !== accountId
    );

    accounts.forEach(item => {
      optionArray.push({
        name: `Transfer To ${item.accountName}`,
        id: item.id,
        fbTransfer: true
      });
    });

    setCurrentOption(value);
    setAnchorEl(null);
    setLoading(true);

    const uid = firebase.auth().currentUser.uid;
    // // Find the category ID...
    const category = budgetData.childCategories
      .concat(optionArray)
      .find(element => element.name === value);

    const categoryID = category.id; // New category
    const userCategoryId = transaction.userCategoryId; // Existing Category

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(
            `${process.env.REACT_APP_ENDPOINT}/update-category/?idToken=${idToken}&uid=${uid}&categoryId=${categoryID}&transactionId=${transaction.id}&transactionAmount=${transaction.amount}&budgetAmountMonth=${budgetAmountMonth}&userCategoryId=${userCategoryId}`
          )
          .then(res => {
            if (res.data.code !== 200) {
              alert("Error updating category. Please contact support");
            } else {
              updateTransactionCategoryId(categoryID);
            }
          })
          .catch(function () {
            setLoading(false);
            alert("Error updating category. Please contact support");
          });
      })
      .catch(function (err) {
        alert("Authentication Error. Please contact support");
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? transaction.id : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <FormControl style={{ borderRadius: 4 }} sx={{ minWidth: 260 }}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          {transaction.isSplit ? (
            <div>
              {splitObject.map(item => {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p style={{ flex: 1 }}>{item.name}</p>
                    <p>
                      R -
                      {String(parseFloat(item.amount).toFixed(2))
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </p>
                  </div>
                );
              })}
              <p
                onClick={() => {
                  setAnchorEl(null);
                  setShowSplitCategory(true);
                }}
                class="hyperlink-text"
                style={{ paddingLeft: 5 }}
              >
                Edit
              </p>
            </div>
          ) : (
            <button
              class="category-selector-button"
              aria-describedby={id}
              type="button"
              onClick={handleClick}
            >
              <div class="category-selector-button-text">{currentOption}</div>
              <ArrowDropDownIcon />
            </button>
          )}
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <div class="category-selector-outer-container">
              <div class="category-search-container">
                <TextField
                  style={{ width: "100%" }}
                  disableClearable
                  size="small"
                  fullWidth={false}
                  autoFocus
                  placeholder="Search Categories"
                  value={searchTerm}
                  onChange={event => {
                    setSearchTerm(event.target.value);
                  }}
                />
                <div>
                  <p
                    onClick={() => {
                      setAnchorEl(null);
                      setShowSplitCategory(true);
                    }}
                    class="hyperlink-text"
                    style={{ paddingLeft: 5 }}
                  >
                    Split Category
                  </p>
                </div>
              </div>
              <Paper class="category-option-selector-paper-outgoing">
                {parentCategories.map(account => (
                  <div key={String(Math.random())} value={account.name}>
                    {getChildren(account).length > 0 && (
                      <div class="parent-category-item">
                        {/* Parent Name */}
                        <div
                          style={{ color: "#ffffff", paddingLeft: 5 }}
                          class="category-option-text"
                        >
                          {account.name}:
                        </div>
                      </div>
                    )}
                    {/* Children */}
                    <div>
                      {getChildren(account).map((child, childIndex) => {
                        return (
                          <div
                            onClick={() => updateCategory(child.name)}
                            class="category-option-item"
                            key={childIndex}
                            value={child.name}
                          >
                            {/* Show a dot at the selected value. */}
                            <div style={{ width: 16 }}>
                              {currentOption === account.name ? (
                                <div class="selected-category-item" />
                              ) : null}
                            </div>
                            <div class="category-option-text">{child.name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </Paper>
            </div>
          </Popper>
        </div>
      </ClickAwayListener>
      {showSplitCategory && (
        <SplitCategory
          open={showSplitCategory}
          memo={transaction.description}
          amount={transaction.amount}
          isSplit={transaction.isSplit}
          transactionId={transaction.id}
          transactionAmount={transaction.amount}
          userCategoryId={transaction.userCategoryId}
          budgetAmountMonth={budgetAmountMonth}
          updateTransactionCategoryId={updateTransactionCategoryId}
          close={() => {
            setShowSplitCategory(false);
          }}
        />
      )}
    </FormControl>
  );
}

export default CategorySelector;
