import React, { useState } from "react";
import AddCategoryGroupModal from "../CategoryList/AddCategoryGroupModal";
import "./BudgetHeading.css";

function BudgetHeading() {
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);

  return (
    <div className="budget-header-container">
      <p className="budget-header-text">Category</p>
      <div style={{ flex: 1 }}>
        <button
          onClick={() => {
            setAddCategoryOpen(true);
          }}
          className="budget-header-button"
        >
          Add Category Group
        </button>
      </div>
      <p style={{ width: "120px" }} className="budget-header-text">
        Budgeted
      </p>
      <p className="budget-header-text">Spent</p>
      <p className="budget-header-text">Available</p>
      {/* To make space for the action items */}
      <div style={{ width: 80 }} />
      <AddCategoryGroupModal
        open={addCategoryOpen}
        onClose={() => {
          setAddCategoryOpen(false);
        }}
      />
    </div>
  );
}

export default BudgetHeading;
