import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import "../App.css";
import LinkAccount from "./Accounts/LinkAccount";
import Loader from "../components/Loader/Loader";
import Budget from "./Budget/BudgetView/Budget";

function Dashboard(props) {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const { showMenu, budgetAmountMonth } = props;

  useEffect(() => {
    if (!userData.userData) {
      return;
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [userData]);

  return (
    <div
      style={{ marginLeft: showMenu ? "260px" : 2 }}
      className="standard-page-container"
    >
      {/* Top header text */}
      <div style={{ width: "100%" }}>
        {loading ? (
          <Loader />
        ) : (
          <div>
            {!userData.userData.onboardingComplete ? (
              <LinkAccount />
            ) : (
              <Budget
                showMenu={showMenu}
                budgetAmountMonth={budgetAmountMonth}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
