import React from "react";
import TextField from "@mui/material/TextField";
import "./TextField.css";

function NavBar(props) {
  const {
    type,
    label,
    disabled,
    helperText,
    error,
    multiline,
    fullWidth,
    onChange,
    value
  } = props;

  return (
    <div className="text-field-container">
      <TextField
        variant="outlined"
        label={label}
        type={type}
        value={value}
        disabled={disabled}
        helperText={helperText}
        error={error}
        multiline={multiline}
        fullWidth={fullWidth}
        size="medium"
        onChange={onChange}
        color="secondary"
        onWheel={event => event.currentTarget.blur()}
      />
    </div>
  );
}

export default NavBar;
