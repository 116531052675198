import React from "react";
import "../../../App.css";
import BudgetHeading from "./BudgetHeading";
import CategoryList from "../CategoryList/CategoryList";

function Budget(props) {
  const { showMenu, budgetAmountMonth } = props;
  return (
    <div style={{ width: "100%" }}>
      {/* Top header text */}
      <div style={{ width: "100%" }}>
        <div
          style={{
            position: "fixed",
            paddingTop: 10,
            backgroundColor: "#fafafa",
            width: showMenu ? window.innerWidth - 260 : window.innerWidth,
            zIndex: 99
          }}
        >
          <BudgetHeading />
        </div>
        <div
          style={{
            paddingTop: "70px",
            width: showMenu ? window.innerWidth - 260 : window.innerWidth
          }}
        >
          <CategoryList budgetAmountMonth={budgetAmountMonth} />
        </div>
      </div>
    </div>
  );
}

export default Budget;
