import React, { useState } from "react";
import "./NavBar.css";
import MenuIcon from "@mui/icons-material/Menu";
import DateSwitcher from "./DateSwitcher";
import BudgetAmounts from "./BudgetAmounts";
import { Dialog } from "@mui/material";
import Profile from "../../screens/Profile/Profile";

function NavBar(props) {
  const [showProfile, setShowProfile] = useState(false);

  const {
    toggleMenu,
    showMenu,
    currentMonth,
    setCurrentMonth,
    budgetAmountMonth
  } = props;

  return (
    <div
      className="navbar-container"
      style={{
        // backgroundColor:
        //   process.env.REACT_APP_PROJECT_ENV === "development" ? "#000000" : null
      }}
    >
      <div className="navbar-internals">
        <button
          className="menu-toggle-button"
          onClick={() => {
            toggleMenu(!showMenu);
          }}
        >
          <MenuIcon />
        </button>
        <div style={{ marginLeft: 20 }}>
          <img
            src={require("../../assets/navbar-logo.png")}
            className="navbar-logo"
            alt="Logo"
          />
        </div>
        <div style={{ marginLeft: 20 }}>
          <DateSwitcher
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
        <div style={{ marginLeft: 30 }}>
          <BudgetAmounts budgetAmountMonth={budgetAmountMonth} />
        </div>
        <div style={{ flex: 1 }} />
        <button
          onClick={() => {
            setShowProfile(true);
          }}
          className="profile-button"
        >
          <p>Profile</p>
        </button>
        <Dialog
          open={showProfile}
          fullWidth
          maxWidth={"sm"}
          onClose={() => {
            setShowProfile(false);
          }}
        >
          <Profile
            closeProfile={() => {
              setShowProfile(false);
            }}
          />
        </Dialog>
      </div>
    </div>
  );
}

export default NavBar;
