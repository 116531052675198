import firebase from "firebase";
import React, { useState } from "react";
import "./CategoryList.css";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

function AddCategoryModal(props) {
  const { open, onClose, parent } = props;
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const createCategory = () => {
    setLoading(true);
    setError("");
    createChildWithParent();
  };

  const createChildWithParent = () => {
    firebase
      .firestore()
      .collection("categories")
      .add({
        name: categoryName,
        isParent: false,
        uid: firebase.auth().currentUser.uid,
        parentId: parent,
        budgeted: [],
        cumulativeTransactionAmounts: []
      })
      .then(() => {
        resetStates();
      });
  };

  const resetStates = () => {
    setCategoryName("");
    setError("");
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div style={{ padding: 30 }}>
        <h2>Add a New Category </h2>
        <div style={{ height: 80, marginTop: 20 }}>
          <TextField
            style={{ width: "100%" }}
            disableClearable
            size="medium"
            fullWidth={false}
            label="Category"
            placeholder="New Category Name"
            value={categoryName}
            onChange={event => {
              setCategoryName(event.target.value);
            }}
          />
        </div>
        <p style={{ color: "#ff0000" }}>{error}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              resetStates();
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              createCategory();
            }}
          >
            {loading ? "Loading..." : "Create"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddCategoryModal;
