import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as firebase from "firebase";
import "./assets/fonts/AvenirLTStd-Black.otf";
import "./assets/fonts/AvenirLTStd-Book.otf";
import "./assets/fonts/AvenirLTStd-Roman.otf";

const firebaseConfigs = {
  development: {
    apiKey: "AIzaSyAbwHSX5IFhyihaWJBPFOVEgYR8zQd98Ds",
    authDomain: "family-budget-dev-70adc.firebaseapp.com",
    projectId: "family-budget-dev-70adc",
    storageBucket: "family-budget-dev-70adc.appspot.com",
    messagingSenderId: "132717097275",
    appId: "1:132717097275:web:cce4f0d43e5f2a5274071e",
    measurementId: "G-V65286CGCW"
  },
  production: {
    apiKey: "AIzaSyAJKmJxuaniKpAzXMcDWzdo1V2viNAKUmU",
    authDomain: "la-budget-dev.firebaseapp.com",
    projectId: "la-budget-dev",
    storageBucket: "la-budget-dev.appspot.com",
    messagingSenderId: "312048737700",
    appId: "1:312048737700:web:12907315fb8b56239fb531",
    measurementId: "G-S4R34Q65FJ"
  }
};

const currentConfig = firebaseConfigs[process.env.REACT_APP_PROJECT_ENV];
firebase.initializeApp(currentConfig);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
