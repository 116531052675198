import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/app";
import "../../../App.css";
import { BudgetContext } from "../../../context/BudgetContext";
import * as API from "../../../api";
import Confirmation from "../../../components/Confirmation";
import { TextField } from "@mui/material";
const formatCurrency = require("../../../helpers/formatCurrency");

function AccountSettings(props) {
  const { closeSettings } = props;
  const budgetData = useContext(BudgetContext);
  const [loading, setLoading] = useState(false);
  const [currentAccount, setCurrentAccount] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    const findAccount = budgetData.accounts.filter(
      item => item.id === window.location.pathname.split("/")[2]
    );
    setCurrentAccount(findAccount[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStates = () => {
    setLoading(false);
    closeSettings();
  };

  const saveAccountInfo = () => {
    setLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        console.log(currentAccount.availableBalance);
        API.updateAccountInfo({
          accountName: currentAccount.accountName,
          availableBalance: currentAccount.availableBalance,
          accountId: currentAccount.id,
          uid: firebase.auth().currentUser.uid,
          idToken: idToken
        })
          .then(result => {
            if (result.code === 200) {
              // Complete
              budgetData.getAccounts();
              closeSettings();
              setLoading(false);
            } else {
              setLoading(false);
              alert("There has been an error, please try again.");
            }
          })
          .catch(err => {
            setLoading(false);
            alert("There has been an error, please try again.");
          });
      });
  };

  const deleteAccount = () => {
    setErrorMessage("");
    setDeleting(true);

    firebase
      .firestore()
      .collection("accounts")
      .doc(currentAccount.id)
      .delete()
      .then(() => {
        setDeleting(false);
        closeSettings();
        window.location.replace("/");
      })
      .catch(err => {
        alert("There has been an error, please try again.");
        setDeleting(false);
      });
  };

  // const editAccountDetails = () => {
  //   // TODO: Update login details...
  // };

  return (
    <div
      style={{
        width: "100%",
        height: 500,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {/* Top header text */}
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <h2 style={{ flex: 1 }}>Account Settings</h2>
        <button
          disabled={loading}
          variant="contained"
          className="large-hyperlink-text"
          style={{
            border: "none",
            backgroundColor: "#fff",
            width: 80,
            height: 30
            // marginRight: 20
          }}
          onClick={() => {
            resetStates();
          }}
        >
          Close
        </button>
      </div>
      {/* Profile Content */}
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <div style={{ height: 80, marginTop: 20 }}>
          <TextField
            style={{ width: "100%" }}
            disableClearable
            size="medium"
            fullWidth={false}
            label="Category Name"
            placeholder="Edit Account Name"
            value={currentAccount.accountName}
            onChange={event => {
              setCurrentAccount({
                ...currentAccount,
                accountName: event.target.value
              });
            }}
          />
        </div>
        <div style={{ height: 80, marginTop: 20 }}>
          <TextField
            style={{ width: "100%" }}
            disableClearable
            size="medium"
            fullWidth={false}
            label="Current Balance"
            placeholder="Edit Account Balance"
            value={currentAccount.availableBalance}
            onChange={event => {
              setCurrentAccount({
                ...currentAccount,
                availableBalance: formatCurrency(event.target.value)
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              saveAccountInfo();
            }}
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
        {/* <div style={{ marginTop: 30 }}>
          <div
            onClick={() => {
              editAccountDetails();
            }}
            class="profile-list-item"
          >
            <p
              style={{ flex: 1, color: "#416b75" }}
              className="large-hyperlink-text"
            >
              Update Login Details
            </p>
          </div>
        </div> */}
        <div style={{ marginTop: 30 }}>
          <div
            onClick={() => {
              setConfirmDelete(true);
            }}
            class="profile-list-item"
          >
            <p
              style={{ flex: 1, color: "#b22222" }}
              className="large-hyperlink-text"
            >
              Delete Account
            </p>
          </div>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        title={`Really delete account?`}
        warning={"This action cannot be undone."}
        error={errorMessage}
        loading={deleting}
        cancel={() => {
          setConfirmDelete(false);
          setErrorMessage("");
        }}
        confirm={() => {
          deleteAccount();
        }}
        cta="Delete"
      />
    </div>
  );
}

export default AccountSettings;
