import React, { useState } from "react";
import axios from "axios";
import firebase from "firebase";
import "./MemoInput.css";

function MemoInput(props) {
  const { setLoading, memo, transactionId, loading } = props;
  const [value, setValue] = useState(memo);

  const save = () => {
    setLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(
            `${
              process.env.REACT_APP_ENDPOINT
            }/update-memo/?idToken=${idToken}&memo=${encodeURIComponent(
              value
            )}&transactionId=${transactionId}&uid=${
              firebase.auth().currentUser.uid
            }`
          )
          .then(res => {
            setLoading(false);
            if (res.data.code !== 200) {
              alert("Error saving memo (1). Please contact support");
            }
          })
          .catch(err => {
            setLoading(false);
            alert(
              "Error saving memo (2). Please contact support" + err.message
            );
          });
      })
      .catch(err => {
        setLoading(false);
        alert("Authentication Error. Please contact support" + err.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "90%"
      }}
    >
      <input
        disabled={loading}
        value={value}
        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
        onChange={e => {
          const input = e.target.value;
          setValue(input);
        }}
        type="text"
        className="memo-input"
        placeholder="No description"
        onBlur={e => {
          if (e.target.value === memo) {
            return;
          }
          save(e.target.value);
        }}
      />
    </div>
  );
}
export default MemoInput;
