import React, { useState, useContext } from "react";
import "../../App.css";
import TextField from "../../components/TextField/TextField";
import * as API from "../../api";
import firebase from "firebase";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { BudgetContext } from "../../context/BudgetContext";

function AddNewAccount(props) {
  const [loading, setLoading] = useState(false);
  const budgetData = useContext(BudgetContext);
  const [accountName, setAccountName] = useState("");
  const [startingBalance, setStartingBalance] = useState("");

  const saveAccountInfo = () => {
    setLoading(true);

    // Save selected accounts.

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        API.saveNewAccount({
          idToken: idToken,
          uid: firebase.auth().currentUser.uid,
          accountName: accountName,
          startingBalance: startingBalance
        })
          .then(result => {
            budgetData.getAccounts();
            props.close();
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            alert(err.message);
          });
      })
      .catch(err => {
        setLoading(false);
        alert("There has been an error, please try again.");
      });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
        <h2 style={{ flex: 1 }}>Add New Account</h2>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <TextField
            onChange={e => {
              setAccountName(e.target.value);
            }}
            fullWidth
            label="Account Name"
            type="text"
          />
          <TextField
            onChange={e => {
              setStartingBalance(e.target.value);
            }}
            fullWidth
            label="Starting Balance"
            type="number"
          />
        </div>
        <button
          disabled={loading}
          variant="contained"
          className="action-button"
          onClick={() => {
            saveAccountInfo();
          }}
        >
          {loading ? "Loading..." : "Save New Account"}
        </button>
      </div>
    </div>
  );
}

export default AddNewAccount;
