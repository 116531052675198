import firebase from "firebase";
import React, { useEffect, useState } from "react";
import "./CategoryList.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChildCategoryItem from "./ChildCategoryItem";
// import Popover from "@mui/material/Popover";
import Confirmation from "../../../components/Confirmation";
import AddCategoryModal from "./AddCategoryModal";
import Add from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ParentCategoryNameInput from "./ParentCategoryNameInput";
import * as API from "../../../api";

function ParentCategoryItem(props) {
  const { name, children, budgetAmountMonth, id } = props;
  const [parentCategoryName, setParentCategoryName] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [budgeted, setBudgeted] = useState(0);
  const [available, setAvailable] = useState(0);
  const [spent, setSpent] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editingParentName, setEditingParentName] = useState(false);
  const [editedValue, setEditedValue] = useState("");

  useEffect(() => {
    setParentCategoryName(name);
  }, [name]);

  const convertToCurrency = amount => {
    let newAmount = parseInt(amount);
    return newAmount.toFixed(2);
  };

  useEffect(() => {
    // Calculate Budgeted
    let totalBudgetedAmount = 0;
    let thisMonthBudgetedAmount = 0;
    let spentAmount = 0;
    // Getting the total available for all months.
    children.forEach(item => {
      if (item.budgeted && typeof item.budgeted === "object") {
        var availableAmount = Object.values(item.budgeted).reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );

        if (item.cumulativeTransactionAmounts[budgetAmountMonth]) {
          spentAmount =
            spentAmount +
            parseFloat(
              parseFloat(
                item.cumulativeTransactionAmounts[budgetAmountMonth]
              ).toFixed(2)
            );
        }

        totalBudgetedAmount =
          parseFloat(totalBudgetedAmount) + parseFloat(availableAmount);
      }
    });

    const tempAvailable = totalBudgetedAmount + parseFloat(spentAmount);
    setAvailable(tempAvailable.toFixed(2));
    // Getting budgeted total for this month only.
    children.forEach(item => {
      if (
        item.budgeted &&
        typeof item.budgeted === "object" &&
        item.budgeted[budgetAmountMonth]
      ) {
        thisMonthBudgetedAmount =
          parseFloat(thisMonthBudgetedAmount) +
          parseFloat(item.budgeted[budgetAmountMonth]);
      }
    });
    setBudgeted(thisMonthBudgetedAmount);
    setSpent(spentAmount);
  }, [children, budgetAmountMonth]);

  const deleteCategory = () => {
    setDeleting(true);
    firebase
      .firestore()
      .collection("categories")
      .doc(id)
      .delete()
      .then(() => {
        setDeleting(false);
        setConfirmDelete(false);
      })
      .catch(err => {
        alert("Error deleting category. Please contact support.");
      });
  };

  return (
    <div>
      <div className="parent-category-container">
        {editingParentName ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ParentCategoryNameInput
              editedValue={editedValue}
              onChangeValue={e => {
                setEditedValue(e);
              }}
            />
            <div
              className="parent-category-button"
              onClick={() => {
                setEditingParentName(false);
                firebase
                  .auth()
                  .currentUser.getIdToken(/* forceRefresh */ true)
                  .then(function (idToken) {
                    API.saveParentCategoryName({
                      value: editedValue,
                      itemId: id,
                      idToken: idToken,
                      uid: firebase.auth().currentUser.uid
                    })
                      .then(() => {
                        setParentCategoryName(editedValue);
                      })
                      .catch(err => {
                        alert(err.message);
                      });
                  });
              }}
            >
              <DoneIcon />
            </div>
            <div
              className="parent-category-button"
              onClick={() => {
                setEditingParentName(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setEditedValue(parentCategoryName);
                setEditingParentName(true);
                // Edit name
              }}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <p className="parent-category-text edit-hover">
                {parentCategoryName}
              </p>
            </div>
            <div
              onClick={() => {
                setAddCategoryModal(true);
                setExpanded(true);
              }}
              className="parent-category-button"
            >
              <Add />
            </div>
          </>
        )}
        <div
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ flex: 1, height: 45 }}
        ></div>
        <p
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ width: "125px" }}
          className="parent-category-text currency"
        >
          {budgeted.toFixed(2)}
        </p>
        <p
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ width: "125px" }}
          className="parent-category-text currency"
        >
          {convertToCurrency(spent)}
        </p>
        <p
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ width: "125px" }}
          className="parent-category-text currency"
        >
          {available}
        </p>
        <div
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ width: 80, textAlign: "center" }}
        >
          {expanded ? (
            <KeyboardArrowDownIcon style={{ color: "#FFFFFF" }} />
          ) : (
            <ChevronRightIcon style={{ color: "#FFFFFF" }} />
          )}
        </div>
      </div>
      {/* Child categories */}
      {expanded && (
        <div className="child-category-container">
          {children.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <p>There are no categories under this category group yet.</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setAddCategoryModal(true);
                  }}
                >
                  <p className="large-hyperlink-text">
                    Add Category Under {parentCategoryName}
                  </p>
                </div>
                <div
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  <p className="large-hyperlink-text">Delete Category Group</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              {children.map((item, index) => {
                return (
                  <ChildCategoryItem
                    item={item}
                    key={index}
                    budgetAmountMonth={budgetAmountMonth}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
      <Confirmation
        open={confirmDelete}
        error={""}
        title={`Really delete category?`}
        warning={"This action cannot be undone."}
        loading={deleting}
        cancel={() => {
          setConfirmDelete(false);
        }}
        confirm={() => {
          deleteCategory();
        }}
        cta="Delete"
      />
      <AddCategoryModal
        open={addCategoryModal}
        parent={id}
        onClose={() => {
          setAddCategoryModal(false);
        }}
      />
    </div>
  );
}

export default ParentCategoryItem;
