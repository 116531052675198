import React, { useState, useEffect } from "react";
import "./NavBar.css";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function DateSwitcher(props) {
  const { currentMonth, setCurrentMonth } = props;
  const [dateRanges, setDateRanges] = useState([]);

  const handleChange = event => {
    setCurrentMonth(event.target.value);
  };

  useEffect(() => {
    const dateArray = [];
    for (let step = 0; step <= 24; step++) {
      dateArray.push(
        moment()
          .add(step - 12, "months")
          .format("MMM YYYY")
      );
    }
    setDateRanges(dateArray);
  }, []);

  // Forward one year, back one year.

  return (
    <div className="date-switcher-container">
      <ArrowBackIosNewIcon
        onClick={() => {
          const newMonth = moment(currentMonth)
            .subtract(1, "month")
            .format("MMM YYYY");
          setCurrentMonth(newMonth);
        }}
        sx={{ fontSize: 18, color: "#fafafa", cursor: "pointer" }}
      />
      <FormControl fullWidth>
        <Select
          style={{
            height: 50,
            padding: 10,
            marginLeft: 10,
            marginRight: 10,
            backgroundColor: "#fafafa",
            color: "#1D1D1D",
            fontWeight: "bold"
          }}
          id="demo-simple-select"
          value={currentMonth}
          onChange={handleChange}
        >
          {dateRanges.map((item, index) => {
            return (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <ArrowForwardIosIcon
        onClick={() => {
          const newMonth = moment(currentMonth)
            .add(1, "month")
            .format("MMM YYYY");
          setCurrentMonth(newMonth);
        }}
        sx={{ fontSize: 18, color: "#fafafa", cursor: "pointer" }}
      />
    </div>
  );
}

export default DateSwitcher;
