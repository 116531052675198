import Paper from "@mui/material/Paper";
import firebase from "firebase";
import React, { useState } from "react";
import "../../App.css";
import "./Accounts.css";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";

function CollectDetails() {
  const [age, setAge] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [numberOfDependents, setNumberOfDependents] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedBondAmount, setSelectedBondAmount] = useState("");
  const [selectedCarLoanAmount, setSelectedCarLoanAmount] = useState("");
  const [selectedCreditCardDebt, setSelectedCreditCardDebt] = useState("");
  const [selectedClothingAccountDebt, setSelectedClothingAccountDebt] =
    useState("");
  const [selectedOtherDebt, setSelectedOtherDebt] = useState("");
  const [error, setError] = useState("");

  const submitForm = () => {
    setLoading(true);
    setError("");

    if (
      age === "" ||
      maritalStatus === "" ||
      numberOfDependents === "" ||
      selectedProvince === "" ||
      selectedBondAmount === "" ||
      selectedCarLoanAmount === "" ||
      selectedCreditCardDebt === "" ||
      selectedClothingAccountDebt === "" ||
      selectedOtherDebt === ""
    ) {
      setLoading(false);
      setError("Please fill out all the required fields.");
    } else {
      firebase
        .firestore()
        .collection("users")
        .doc(firebase.auth().currentUser.uid)
        .update({
          age: age,
          maritalStatus: maritalStatus,
          numberOfDependents: numberOfDependents,
          selectedProvince: selectedProvince,
          selectedBondAmount: selectedBondAmount,
          selectedCarLoanAmount: selectedCarLoanAmount,
          selectedCreditCardDebt: selectedCreditCardDebt,
          selectedClothingAccountDebt: selectedClothingAccountDebt,
          selectedOtherDebt: selectedCreditCardDebt,
          detailsCollected: true
        })
        .then(() => {
          setLoading(false);
          setError("");
        });
    }
  };

  const bondAmountOptions = [
    "Nothing",
    "Up to R1 million",
    "Up to R2 million",
    "Up to R3 million",
    "Up to R4 million",
    "Up to R5 million",
    "Up to R10 million",
    "R10 million+"
  ];

  const carLoanAmountOptions = [
    "Nothing",
    "Up to R50,000",
    "Up to R100,000",
    "Up to R200,000",
    "Up to R300,000",
    "Up to R500,000",
    "R500,000+"
  ];

  const creditCardDebtOptions = [
    "None",
    "Up to R5,000",
    "Up to R10,000",
    "Up to R20,000",
    "Up to R30,000",
    "Up to R50,000",
    "R50,000+"
  ];

  const clothingAccountDebtOptions = [
    "None",
    "Up to R1,000",
    "Up to R2,000",
    "Up to R5,000",
    "Up to R10,000",
    "Up to R20,000",
    "R20,000+"
  ];

  const otherDebtOptions = [
    "None",
    "Up to R50,000",
    "Up to R100,000",
    "Up to R200,000",
    "Up to R500,000",
    "Up to R1 million"
  ];

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
        <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
          <h3>Create Your Profile</h3>
          <div style={{ marginTop: 20 }}>
            <p>
              The information below will be used to give you unique insights
              that are designed to help you in your specific situation.
              <br />
              <br />
              <b>
                All your data is encrypted, so even if we wanted to see your
                transactions and balances, we couldn't.
              </b>
            </p>
          </div>
        </Paper>
        <Paper style={{ marginBottom: 10, padding: 10 }}>
          <div>
            <TextField
              label="Age"
              value={age}
              onChange={e => setAge(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <TextField
              label="Number of Dependents"
              value={numberOfDependents}
              onChange={e => setNumberOfDependents(e.target.value)}
              fullWidth
              margin="normal"
              type="number"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="marital-status-label">Marital Status</InputLabel>
              <Select
                labelId="marital-status-label"
                id="marital-status"
                value={maritalStatus}
                onChange={e => setMaritalStatus(e.target.value)}
                label="Marital Status"
              >
                <MenuItem value="single">Single</MenuItem>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="divorced">Divorced</MenuItem>
                <MenuItem value="widowed">Widowed</MenuItem>
                <MenuItem value="separated">Separated</MenuItem>
                <MenuItem value="domestic">Domestic Partnership</MenuItem>
                <MenuItem value="civil">Civil Union</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="province-label">Province</InputLabel>
              <Select
                labelId="province-label"
                id="province"
                value={selectedProvince}
                onChange={e => setSelectedProvince(e.target.value)}
                label="Province"
              >
                <MenuItem value="eastern_cape">Eastern Cape</MenuItem>
                <MenuItem value="free_state">Free State</MenuItem>
                <MenuItem value="gauteng">Gauteng</MenuItem>
                <MenuItem value="kwazulu_natal">KwaZulu-Natal</MenuItem>
                <MenuItem value="limpopo">Limpopo</MenuItem>
                <MenuItem value="mpumalanga">Mpumalanga</MenuItem>
                <MenuItem value="north_west">North West</MenuItem>
                <MenuItem value="northern_cape">Northern Cape</MenuItem>
                <MenuItem value="western_cape">Western Cape</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="bond-amount-label">
                Bond Amount on House
              </InputLabel>
              <Select
                labelId="bond-amount-label"
                id="bond-amount"
                value={selectedBondAmount}
                onChange={e => setSelectedBondAmount(e.target.value)}
                label="Bond Amount on House"
              >
                {bondAmountOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="car-loan-amount-label">
                Outstanding Car Loan Amount
              </InputLabel>
              <Select
                labelId="car-loan-amount-label"
                id="car-loan-amount"
                value={selectedCarLoanAmount}
                onChange={e => setSelectedCarLoanAmount(e.target.value)}
                label="Outstanding Car Loans Amount (Total)"
              >
                {carLoanAmountOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="credit-card-debt-label">
                Credit Card Debt
              </InputLabel>
              <Select
                labelId="credit-card-debt-label"
                id="credit-card-debt"
                value={selectedCreditCardDebt}
                onChange={e => setSelectedCreditCardDebt(e.target.value)}
                label="Credit Card Debt"
              >
                {creditCardDebtOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="clothing-account-debt-label">
                Clothing Account Debt
              </InputLabel>
              <Select
                labelId="clothing-account-debt-label"
                id="clothing-account-debt"
                value={selectedClothingAccountDebt}
                onChange={e => setSelectedClothingAccountDebt(e.target.value)}
                label="Clothing Account Debt"
              >
                {clothingAccountDebtOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="other-debt-label">Other Debts</InputLabel>
              <Select
                labelId="other-debt-label"
                id="other-debt"
                value={selectedOtherDebt}
                onChange={e => setSelectedOtherDebt(e.target.value)}
                label="Other Debts"
              >
                {otherDebtOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error && <p className="error-text">{error}</p>}
            <div style={{ marginTop: 30 }}>
              <button
                style={{
                  backgroundColor: loading ? "#647275" : null
                }}
                className="action-button"
                disabled={loading}
                onClick={() => {
                  submitForm();
                }}
              >
                {loading ? "Loading..." : "Next"}
              </button>
            </div>
          </div>
        </Paper>
        <div style={{ height: 500 }} />
      </div>
    </div>
  );
}

export default CollectDetails;
