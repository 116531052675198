import React, { useState, useContext } from "react";
import firebase from "firebase/app";
import "../../App.css";
import "./Profile.css";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { UserContext } from "../../context/UserContext";
import moment from "moment";
import axios from "axios";
import Confirmation from "../../components/Confirmation";
import ManageLogins from "../Settings/ManageLogins";

function Profile(props) {
  const { closeProfile } = props;
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [confirmStartAfresh, setConfirmStartAfresh] = useState(false);
  const [openManageLogins, setOpenManageLogins] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetStates = () => {
    closeProfile();
  };

  const startAfresh = () => {
    setProcessing(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(
            `${process.env.REACT_APP_ENDPOINT}/start-afresh?uid=${
              firebase.auth().currentUser.uid
            }&idToken=${idToken}`
          )
          .then(function (response) {
            setProcessing(false);
            if (response.data.code === 200) {
              setConfirmStartAfresh(false);
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            setProcessing(false);
            alert(error.message);
          });
      });
  };

  const ProfileItem = props => {
    return (
      <div onClick={props.onClick} class="profile-list-item">
        <p style={{ flex: 1 }} className="large-hyperlink-text">
          {props.title}
        </p>
        <ChevronRight style={{ fontSize: 24 }} />
      </div>
    );
  };

  const ProfileInfoItem = props => {
    return (
      <div class="profile-list-info-item">
        <p style={{ flex: 1 }} className="profile-list-item-text">
          {props.title} {props.value}
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        height: 500,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {/* Top header text */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <h2 style={{ flex: 1 }}>Your Profile</h2>
        <button
          variant="contained"
          className="large-hyperlink-text"
          style={{
            border: "none",
            backgroundColor: "#fff",
            width: 80,
            height: 30,
            marginRight: 20
          }}
          onClick={() => {
            resetStates();
          }}
        >
          Close
        </button>
      </div>
      {/* Profile Content */}
      <ProfileInfoItem
        title={"Email Address:"}
        value={userData.userData.email}
      />
      <ProfileInfoItem
        title={"Account Status:"}
        value={userData.userData.paymentActive ? "Active" : "Inactive"}
      />
      {userData.userData.onboardingComplete && (
        <>
          <ProfileInfoItem
            title={"Date Budget Started:"}
            value={moment
              .unix(userData.userData.dateLinkedUnix)
              .format("YYYY / MM / DD")}
          />
          <ProfileItem
            title={"Start Afresh"}
            onClick={() => {
              setConfirmStartAfresh(true);
            }}
          />
          <ProfileItem
            title={loading ? "Loading..." : "Manage Subscription"}
            onClick={() => {
              setLoading(true);
              firebase
                .auth()
                .currentUser.getIdToken(/* forceRefresh */ true)
                .then(function (idToken) {
                  axios
                    .get(
                      `${process.env.REACT_APP_ENDPOINT}/create-customer-portal-session?customerId=${userData.userData.stipeCustomerId}&idToken=${idToken}`
                    )
                    .then(function (response) {
                      setLoading(false);
                      if (response.data.code === 200) {
                        window.open(response.data.url, "_blank");
                      } else {
                        alert(response.data.message);
                      }
                    })
                    .catch(function (error) {
                      setLoading(false);
                      alert(error.message);
                    });
                });
            }}
          />
        </>
      )}
      <ProfileItem
        title={"Sign Out"}
        onClick={() => {
          firebase.auth().signOut();
        }}
      />
      <Confirmation
        open={confirmStartAfresh}
        title={`Really start afresh?`}
        warning={"You will lose all your transactions and budget data."}
        error={errorMessage}
        loading={processing}
        cancel={() => {
          setConfirmStartAfresh(false);
          setErrorMessage("");
        }}
        confirm={() => {
          startAfresh();
        }}
        cta="Start Afresh"
      />
      <ManageLogins
        close={() => {
          setOpenManageLogins(false);
        }}
        open={openManageLogins}
      />
    </div>
  );
}

export default Profile;
