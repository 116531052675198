import React, { useState } from "react";
import "./AccountView.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { Dialog } from "@mui/material";
import AccountSettings from "./AccountSettings";

function AccountViewHeading(props) {
  const [showAccountSettings, setShowAccountSettings] = useState(false);

  return (
    <div className="account-header-container">
      <p style={{ paddingLeft: 10 }} className="account-header-text">
        Date
      </p>
      <p
        style={{ width: "280px", paddingRight: 30 }}
        className="account-header-text"
      >
        Category
      </p>
      <div style={{ flex: 1 }}>
        <p className="account-header-text">Memo</p>
      </div>
      <p className="account-header-text">Outflow</p>
      <p className="account-header-text">Inflow</p>
      <div
        onClick={() => {
          setShowAccountSettings(true);
        }}
        style={{ width: 80 }}
      >
        <SettingsIcon
          style={{ color: "#FFFFFF", cursor: "pointer" }}
          className="menu-item-icon"
        />
      </div>
      <Dialog
        open={showAccountSettings}
        fullWidth
        maxWidth={"md"}
        onClose={() => {
          setShowAccountSettings(false);
        }}
      >
        <AccountSettings
          closeSettings={() => {
            setShowAccountSettings(false);
          }}
        />
      </Dialog>
    </div>
  );
}

export default AccountViewHeading;
