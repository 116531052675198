import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TextField from "../../components/TextField/TextField";
import firebase from "firebase/app";
import "../../App.css";

function Register(props) {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const register = () => {
    setError("");
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email.trim(), password)
      .then(() => {
        // User created.
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  };

  const logKey = e => {
    if (e.code === "Enter") {
      register();
    }
  };

  return (
    <div>
      <Paper className="login-paper">
        <div
          onKeyDown={e => {
            logKey(e);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 10
          }}
        >
          <img
            style={{
              width: "90%",
              backgroundColor: "#455560",
              padding: 15,
              borderRadius: 15,
              marginBottom: 30
            }}
            src={require("../../assets/navbar-logo.png")}
            alt="Logo"
          />
          <h2 style={{ textAlign: "center" }}>Sign Up</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            Enter your credentials to continue
          </p>
          {/* Email */}
          <TextField
            type="text"
            label="Email Address"
            fullWidth={true}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          {/* Password */}
          <TextField
            type="password"
            label="Password"
            fullWidth={true}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <button
            disabled={loading}
            className="full-action-button"
            onClick={() => {
              register();
            }}
          >
            {loading ? "Loading..." : "Sign Up"}
          </button>
          <p className="error-text">{error}</p>
          <div className="divider" />
          <div
            onClick={() => {
              props.switchToLogin();
            }}
          >
            <p className="large-hyperlink-text">I already have an account</p>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default Register;
