import firebase from "firebase";
import React, { useState } from "react";
import "./CategoryList.css";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Confirmation from "../../../components/Confirmation";

function EditCategoryModal(props) {
  const { open, onClose, category } = props;
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryName, setCategoryName] = useState(category.name);

  const resetStates = () => {
    onClose();
    setLoading(false);
    setDeleting(false);
    setConfirmDelete(false);
    setErrorMessage("");
    setCategoryName("");
  };

  const saveCategory = () => {
    setLoading(true);

    firebase
      .firestore()
      .collection("categories")
      .doc(category.id)
      .update({
        name: categoryName
      })
      .then(() => {
        resetStates();
      })
      .catch(err => {
        setLoading(false);
        alert("Error saving categories. Please contact support.");
      });
  };

  const deleteCategory = () => {
    setErrorMessage("");
    setDeleting(true);
    console.log(firebase.auth().currentUser.uid);
    firebase
      .firestore()
      .collection("transactions")
      .where("userCategoryId", "==", category.id)
      .get()
      .then(docs => {
        if (docs.empty) {
          firebase
            .firestore()
            .collection("categories")
            .doc(category.id)
            .delete()
            .then(() => {
              resetStates();
            })
            .catch(err => {
              console.log(err.message, "HERE THEN???");
              alert("Error deleting categories. Please contact support.");
              resetStates();
            });
        } else {
          setDeleting(false);
          setErrorMessage(
            `The category needs to have no transactions associated with it before you can delete it. Please categorise all transactions associated with ${category.name} and try again.`
          );
          resetStates();
        }
      })
      .catch(err => {
        console.log(err.message);
        alert("Authentication Error. Please contact support.");
        resetStates();
      });
  };

  return (
    <Dialog
      onClose={() => {
        resetStates();
      }}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <div style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <h2 style={{ flex: 1 }}>Category Options</h2>
          <button
            disabled={loading}
            variant="contained"
            className="large-hyperlink-text"
            style={{
              border: "none",
              backgroundColor: "#fff",
              width: 80,
              height: 30
            }}
            onClick={() => {
              resetStates();
            }}
          >
            Close
          </button>
        </div>
        <div style={{ height: 80, marginTop: 20 }}>
          <TextField
            style={{ width: "100%" }}
            disableClearable
            size="medium"
            fullWidth={false}
            label="Category Name"
            placeholder="Edit Category Name"
            value={categoryName}
            onChange={event => {
              setCategoryName(event.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <button
            disabled={deleting}
            variant="contained"
            className="outline-action-button"
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            Delete
          </button>
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              saveCategory();
            }}
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </div>
      </div>
      <Confirmation
        open={confirmDelete}
        title={`Really delete category?`}
        warning={"This action cannot be undone."}
        error={errorMessage}
        loading={deleting}
        cancel={() => {
          setConfirmDelete(false);
          setErrorMessage("");
        }}
        confirm={() => {
          deleteCategory();
        }}
        cta="Delete"
      />
    </Dialog>
  );
}

export default EditCategoryModal;
