import { hot } from "react-hot-loader/root";
import React, { useState, useEffect } from "react";
import "./App.css";
import * as firebase from "firebase";
import Loading from "./screens/Loading";
import MainApp from "./screens/MainApp";
import UserContextProvider from "./context/UserContext";
import AuthPage from "./screens/Auth/AuthPage";
import NavBar from "./components/NavBar/NavBar";
import BudgetContextProvider from "./context/BudgetContext";
import moment from "moment";
import MobileNotice from "./screens/MobileNotice";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM YYYY"));
  const [budgetAmountMonth, setBudgetAmountMonth] = useState(
    moment().format("MMYYYY")
  );
  const [isMobile, setIsMobile] = useState(true);

  const toggleMenu = value => {
    setShowMenu(value);
  };

  firebase.auth().onAuthStateChanged(function (user) {
    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (user) {
      console.log(user.uid);
      setAuthenticated(true);
      setLoading(false);
    } else {
      setAuthenticated(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  });

  useEffect(() => {
    setBudgetAmountMonth(moment(currentMonth).format("MMYYYY"));
  }, [currentMonth]);

  return (
    <>
      {/* If mobile, show the   message here... */}
      {loading && <Loading />}
      {!loading && isMobile ? (
        <MobileNotice />
      ) : (
        <>
          {/* Login pages */}
          {!loading && !authenticated && <AuthPage />}
          {/* Nav Bar */}
          {!loading && authenticated && (
            <UserContextProvider>
              <BudgetContextProvider>
                <NavBar
                  toggleMenu={toggleMenu}
                  showMenu={showMenu}
                  currentMonth={currentMonth}
                  setCurrentMonth={setCurrentMonth}
                  budgetAmountMonth={budgetAmountMonth}
                />
              </BudgetContextProvider>
            </UserContextProvider>
          )}
          {/* Actual App Content */}
          {!loading && authenticated && (
            <UserContextProvider>
              <BudgetContextProvider>
                <MainApp
                  showMenu={showMenu}
                  budgetAmountMonth={budgetAmountMonth}
                />
              </BudgetContextProvider>
            </UserContextProvider>
          )}
        </>
      )}
    </>
  );
}

export default hot(App);
