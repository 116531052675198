import firebase from "firebase";
import React, { useState, createContext, useEffect } from "react";
import axios from "axios";

export const BudgetContext = createContext();

const BudgetContextProvider = props => {
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);

  useEffect(() => {
    getCategories();
    getAccounts();
  }, []);

  const getCategories = () => {
    firebase
      .firestore()
      .collection("categories")
      .where("uid", "==", firebase.auth().currentUser.uid)
      .orderBy("name", "asc")
      .onSnapshot(val => {
        var childCategories = [];
        var parentCategories = [];
        val.forEach(doc => {
          const item = doc.data();
          item.id = doc.id;
          if (item.isParent) {
            parentCategories.push(item);
          } else {
            childCategories.push(item);
          }
        });
        setParentCategories(parentCategories);
        setChildCategories(childCategories);
      });
  };

  const getAccounts = overrideOnboardingCheck => {
    return new Promise(function (resolve, reject) {
      setLoadingAccounts(true);
      const uid = firebase.auth().currentUser.uid;
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get()
        .then(doc => {
          if (!doc.exists) {
            return;
          }
          if (doc.data().onboardingComplete || overrideOnboardingCheck) { 
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then(function (idToken) {
                axios
                  .get(
                    `${process.env.REACT_APP_ENDPOINT}/get-user-accounts/?idToken=${idToken}&uid=${uid}`
                  )
                  .then(res => {
                    if (res.data.code !== 200) {
                      setLoadingAccounts(false);
                      alert(
                        "Error getting accounts (1). Please contact support"
                      );
                      reject();
                    } else {
                      setAccounts(res.data.data);
                      setLoadingAccounts(false);
                      resolve();
                    }
                  })
                  .catch(function (err) {
                    console.log(err);
                    setLoadingAccounts(false);
                    alert("Error getting accounts (2). Please contact support");
                    reject();
                  });
              })
              .catch(function () {
                setLoadingAccounts(false);
                alert("Authentication Error. Please contact support");
                reject();
              });
          } else {
            console.log("Onboarding not complete.");
          }
        });
    });
  };

  return (
    <BudgetContext.Provider
      value={{
        parentCategories: parentCategories,
        childCategories: childCategories,
        accounts: accounts,
        loadingAccounts: loadingAccounts,
        getAccounts: getAccounts
      }}
    >
      {props.children}
    </BudgetContext.Provider>
  );
};

export default BudgetContextProvider;
