import React from "react";

function MobileNotice() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh"
      }}
    >
      <div
        style={{
          backgroundColor: "#fafafa",
          textAlign: "center",
          flex: 1
        }}
      >
        <img
          src={require("../assets/navbar-logo.png")}
          style={{
            width: "auto",
            height: 30,
            marginTop: "10%",
            backgroundColor: "#647275",
            padding: 15,
            borderRadius: 15
          }}
          alt="Family Budget Logo"
        />
        <div style={{ padding: 30 }}>
          <h3>Sorry, we're still working on supporting mobile phones.</h3>
          <p>
            Bookmark this page to return to it when you're on a computer again.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MobileNotice;
