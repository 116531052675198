import React from "react";
import "./BudgetHeading.css";

function BudgetTotals(props) {
  const { budgeted, spent } = props;
  return (
    <div className="budget-footer-container">
      <p style={{ flex: 1 }} className="budget-header-text">
        Totals
      </p>
      <p style={{ width: "120px" }} className="budget-header-text">
        R {budgeted.toFixed(2)}
      </p>
      <p style={{ width: "125px" }} c className="budget-header-text">
        R {spent.toFixed(2)}
      </p>
      <p style={{ width: "125px" }} c className="budget-header-text">
        R {(budgeted + spent).toFixed(2)}
      </p>
      {/* To make space for the action items */}
      <div style={{ width: 80 }} />
    </div>
  );
}

export default BudgetTotals;
