import React, { useContext, useEffect, useState } from "react";
import "./IncomeCategorySelector.css";
import { BudgetContext } from "../../context/BudgetContext";
import FormControl from "@mui/material/FormControl";
import * as API from "../../api";
import firebase from "firebase";
import Popper from "@mui/material/Popper";
import { Paper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function IncomeCategorySelector(props) {
  const [optionList, setOptionList] = useState([]);
  const [currentOption, setCurrentOption] = useState("");
  const [fetching, setFetching] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const { transaction, setLoading } = props;

  const budgetData = useContext(BudgetContext);

  useEffect(() => {
    const accountId = window.location.pathname.split("/")[2];
    const accounts = budgetData.accounts.filter(
      account => account.id !== accountId
    );

    var optionArray = [];
    optionArray.push({ name: "Add To Available To Assign" });
    accounts.forEach(item => {
      optionArray.push({ name: `Transfer From ${item.accountName}` });
    });
    setOptionList(optionArray);
    setCurrentOption(transaction.incomeDescription || "No Category");
    if (budgetData.accounts.length > 0) {
      setFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetData.accounts]);

  const updateCategory = value => {
    setLoading(true);
    const uid = firebase.auth().currentUser.uid;

    let mustBeRemovedFromAvailable = null;
    if (currentOption === "Add To Available To Assign") {
      mustBeRemovedFromAvailable = true;
    } else {
      mustBeRemovedFromAvailable = false;
    }

    setCurrentOption(value);
    setAnchorEl(null);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        API.updateIncomeCategory({
          uid: uid,
          idToken: idToken,
          transactionId: transaction.id,
          transactionAmount: transaction.amount,
          descriptionValue: value,
          mustBeRemovedFromAvailable: mustBeRemovedFromAvailable
        })
          .then(res => {
            if (res.code === 200) {
              firebase
                .firestore()
                .collection("transactions")
                .doc(transaction.id)
                .update({
                  incomeDescription: value,
                  userCategoryId: "Income"
                })
                .then(() => {
                  props.updateTransactionCategoryId("Income");
                  setLoading(false);
                })
                .catch(function () {
                  setLoading(false);
                  alert("Error updating category. Please contact support");
                });
            } else {
              alert("Error updating category. Please contact support");
            }
          })
          .catch(function () {
            setLoading(false);
            alert("Error updating category. Please contact support");
          });
      })
      .catch(function (err) {
        alert("Authentication Error. Please contact support");
      });
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? transaction.id : undefined;

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <FormControl sx={{ minWidth: 260 }}>
      {fetching ? (
        <div class="category-loading-text">Loading...</div>
      ) : (
        <div>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <button
                class="category-selector-button"
                aria-describedby={id}
                type="button"
                onClick={handleClick}
              >
                <div class="category-selector-button-text">{currentOption}</div>
                <ArrowDropDownIcon />
              </button>
              <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper class="category-option-selector-paper">
                  {optionList.map(account => (
                    <div
                      onClick={() => updateCategory(account.name)}
                      class="category-option-item"
                      key={String(Math.random())}
                      value={account.name}
                    >
                      <div style={{ width: 16 }}>
                        {currentOption === account.name ? (
                          <div class="selected-category-item" />
                        ) : null}
                      </div>
                      <div class="category-option-text">{account.name}</div>
                    </div>
                  ))}
                </Paper>
              </Popper>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </FormControl>
  );
}

export default IncomeCategorySelector;

// The back end stuff is working, but not the select stuff.
