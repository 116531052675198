import React, { useContext, useState } from "react";
import { Paper } from "@mui/material";
import "../../App.css";
import firebase from "firebase";
import { BudgetContext } from "../../context/BudgetContext";

function SuccessScreen() {
  const [loading, setLoading] = useState(false);
  const budgetData = useContext(BudgetContext);

  const getFirstAccountsAndTransactions = () => {
    setLoading(true);
    const uid = firebase.auth().currentUser.uid;

    budgetData
      .getAccounts(true)
      .then(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .update({
            onboardingComplete: true
          })
          .then(() => {
            setLoading(false);
          })
          .catch(err => {
            alert("Error getting accounts. Please contact support.");
          });
      })
      .catch(err => {
        alert("Error getting accounts. Please contact support.");
      });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
        <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
          <h3>Almost there!</h3>
        </Paper>
        <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
          <p>
            You have successfully linked your bank accounts and are almost ready
            to start budgeting and becoming smarter with your money!
            <br />
            <br />
            <b>
              All that's left is for us to bring your accounts into your profile
              and you'll be all ready to go!
            </b>
          </p>
          <div style={{ marginBottom: 20 }}>
            <button
              disabled={loading}
              variant="contained"
              className="action-button"
              onClick={() => {
                getFirstAccountsAndTransactions();
              }}
            >
              {loading ? "Just a minute..." : "Let's Go!"}
            </button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default SuccessScreen;
