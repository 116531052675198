import React, { useState } from "react";
import firebase from "firebase/app";
import "../../App.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as API from "../../api";
import Loader from "../../components/Loader/Loader";
import ChevronRight from "@mui/icons-material/ChevronRight";
import TextField from "../../components/TextField/TextField";

function ManageLogins(props) {
  const loading = true;
  const [editLoginDetails, setEditLoginDetails] = useState(false);
  const [selectedLoginDetails, setSelectedLoginDetails] = useState([]);
  const [updatingLogins, setUpdatingLogins] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginList = [];
  const { open, close } = props;

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: 20, marginBottom: 50 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <h2 style={{ flex: 1 }}>Manage Logins</h2>
            <button
              variant="contained"
              className="large-hyperlink-text"
              style={{
                border: "none",
                backgroundColor: "#fff",
                width: 80,
                height: 30,
                marginRight: 20
              }}
              onClick={() => {
                close();
              }}
            >
              Close
            </button>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              {loginList.length > 0 ? (
                loginList.map(item => (
                  <div
                    onClick={() => {
                      console.log(item, "ITEM");
                      setUsername("");
                      setPassword("");
                      setSelectedLoginDetails(item);
                      setEditLoginDetails(true);
                    }}
                    class="profile-list-item"
                    style={{ width: "98%" }}
                  >
                    <p
                      style={{
                        flex: 1,
                        paddingLeft: 10,
                        textTransform: "capitalize"
                      }}
                      className="large-hyperlink-text"
                    >
                      Bank: {item.bank} (Added {item.dateAdded})
                    </p>
                    <ChevronRight style={{ fontSize: 24 }} />
                  </div>
                ))
              ) : (
                <div>
                  <p style={{ textAlign: "center" }}>
                    There are no login details to display
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Dialog>
      <Dialog maxWidth="md" open={editLoginDetails}>
        <DialogTitle id="alert-dialog-title">Edit Login Details</DialogTitle>
        <DialogContent>
          <div
            style={{
              width: "100%"
            }}
          >
            <div>
              <TextField
                type="text"
                label={`Username`}
                fullWidth={true}
                value={username}
                onChange={e => {
                  setUsername(e.target.value);
                }}
              />
            </div>
            <div style={{ marginTop: 10 }}>
              {/* Password */}
              <TextField
                type="password"
                label="Password"
                fullWidth={true}
                value={password}
                onChange={e => {
                  setPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="outline-action-button"
            disabled={updatingLogins}
            onClick={() => {
              setEditLoginDetails(false);
            }}
          >
            Cancel
          </button>
          <button
            className="action-button"
            disabled={updatingLogins}
            onClick={() => {
              if (username.length === 0 && password.length === 0) {
                alert("Please make sure you have completed all the fields");
              } else {
                setUpdatingLogins(true);
                firebase
                  .auth()
                  .currentUser.getIdToken(/* forceRefresh */ true)
                  .then(function (idToken) {
                    API.updateLogins({
                      idToken: idToken,
                      username: username,
                      password: password,
                      id: selectedLoginDetails.id,
                      uid: firebase.auth().currentUser.uid
                    }).then(result => {
                      if (result.code === 200) {
                        setUpdatingLogins(false);
                        setEditLoginDetails(false);
                        setUsername("");
                        setPassword("");
                        setSelectedLoginDetails([]);
                        alert("Login details updated successfully");
                      } else {
                        setUpdatingLogins(false);
                        alert(
                          "There has been an error. Please contact support"
                        );
                      }
                    });
                  })
                  .catch(function (err) {
                    setUpdatingLogins(false);
                    alert("Authentication Error. Please contact support");
                  });
              }
            }}
            color="default"
            autoFocus
          >
            {updatingLogins ? "Loading.." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ManageLogins;
