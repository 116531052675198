import React, { useContext, useEffect, useState } from "react";
import { BudgetContext } from "../../../context/BudgetContext";
import "./CategoryList.css";
import ParentCategoryItem from "./ParentCategoryItem";
import BudgetTotals from "../BudgetView/BudgetTotals";

function CategoryList(props) {
  const budgetData = useContext(BudgetContext);
  const { budgetAmountMonth } = props;
  const [spent, setSpent] = useState(0);
  const [budgeted, setBudgeted] = useState(0);

  const getChildren = item => {
    const filteredCategories = budgetData.childCategories.filter(
      child => child.parentId === item.id
    );
    return filteredCategories;
  };

  useEffect(() => {
    let tempSpent = 0;
    let tempBudgeted = 0;

    budgetData.childCategories.forEach((item, index) => {
      if (item.cumulativeTransactionAmounts[budgetAmountMonth]) {
        if (
          parseFloat(item.cumulativeTransactionAmounts[budgetAmountMonth]) < 0
        ) {
          tempSpent =
            tempSpent +
              parseFloat(
                item.cumulativeTransactionAmounts[budgetAmountMonth]
              ) || 0;
        }
      }

      if (item.budgeted) {
        if (parseFloat(item.budgeted[budgetAmountMonth]) > 0) {
          tempBudgeted =
            tempBudgeted + parseFloat(item.budgeted[budgetAmountMonth]);
        }
      }

      if (index + 1 === budgetData.childCategories.length) {
        console.log("Done..");
        setBudgeted(tempBudgeted);
        setSpent(tempSpent);
      }
    });
  }, [budgetData.childCategories, budgetAmountMonth]);

  return (
    <div>
      {budgetData.parentCategories &&
        budgetData.parentCategories.map((item, index) => {
          return (
            <ParentCategoryItem
              key={index}
              name={item.name}
              id={item.id}
              children={getChildren(item)}
              budgetAmountMonth={budgetAmountMonth}
            />
          );
        })}
      <BudgetTotals budgeted={budgeted} spent={spent} />
    </div>
  );
}

export default CategoryList;
