import firebase from "firebase";
import React, { useState, useContext } from "react";
import "./CategoryList.css";
import { BudgetContext } from "../../../context/BudgetContext";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

function AddCategoryGroupModal(props) {
  const budgetData = useContext(BudgetContext);
  const { open, onClose } = props;
  const [groupName, setGroupName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const createCategory = () => {
    setLoading(true);
    setError("");
    if (groupName === "") {
      setError("Please add category group name.");
      setLoading(false);
      return;
    }

    const parentIndex = budgetData.parentCategories.find(
      element => element.name === groupName
    );

    if (parentIndex) {
      setError("There is already a group with the same name.");
      setLoading(false);
    } else {
      createCategoryGroup();
    }
  };

  const createCategoryGroup = () => {
    firebase
      .firestore()
      .collection("categories")
      .add({
        name: groupName,
        isParent: true,
        uid: firebase.auth().currentUser.uid
      })
      .then(doc => {
        resetStates();
      })
      .catch(err => {
        console.log(err.message)
        alert("There has been an error. Please try again or contact support");
        resetStates();
      });
  };

  const resetStates = () => {
    setError("");
    setGroupName("");
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div style={{ padding: 30 }}>
        <h2>Add a New Category Group</h2>
        <div style={{ height: 80, marginTop: 20 }}>
          <TextField
            style={{ width: "100%" }}
            disableClearable
            size="medium"
            fullWidth={false}
            label="Category Name"
            placeholder="New Category Group Name"
            value={groupName}
            onChange={event => {
              setGroupName(event.target.value);
            }}
          />
        </div>
        <p style={{ color: "#ff0000" }}>{error}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              resetStates();
            }}
          >
            Cancel
          </button>
          <button
            disabled={loading}
            variant="contained"
            className="action-button"
            onClick={() => {
              createCategory();
            }}
          >
            {loading ? "Loading..." : "Create"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default AddCategoryGroupModal;
