import React from "react";

function ParentCategoryNameInput(props) {
  const { editedValue, onChangeValue } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "90%"
      }}
    >
      <input
        value={editedValue}
        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
        onChange={e => {
          const input = e.target.value;
          onChangeValue(input);
        }}
        type="text"
        className="parent-category-input"
        placeholder="Category Name"
      />
    </div>
  );
}
export default ParentCategoryNameInput;
