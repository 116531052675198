import React, { useEffect, useState } from "react";
import "./AmountInput.css";
const formatCurrency = require("../../helpers/formatCurrency");

function AmountInput(props) {
  const { budgetedAmount, save } = props;
  const [amount, setAmount] = useState(budgetedAmount);

  useEffect(() => {
    setAmount(budgetedAmount);
  }, [budgetedAmount]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "120px"
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          const item = document.getElementById(props.reference);
          save(item.value);
          item.blur();
        }
      }}
    >
      {/* Adornment */}
      <p className="amount-input-adornment">R {props.key}</p>
      <input
        id={props.reference}
        value={amount}
        pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
        onChange={e => {
          const input = e.target.value;
          setAmount(formatCurrency(input));
        }}
        type="currency"
        className="amount-input"
        placeholder="0.00"
        onBlur={e => {
          save(e.target.value);
        }}
      />
    </div>
  );
}
export default AmountInput;
