import React from "react";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh"
      }}
    >
      <div
        style={{
          backgroundColor: "#fafafa",
          textAlign: "center",
          flex: 1
        }}
      >
        <img
          src={require("../assets/logo.png")}
          style={{
            width: 200,
            height: 200,
            marginTop: "10%",
            backgroundColor: "#EEB111",
            padding: 15,
            borderRadius: 15
          }}
          alt="Family Budget Logo"
        />
        <div>
          <h3>Loading...</h3>
        </div>
      </div>
    </div>
  );
}

export default Loading;
