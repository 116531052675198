import { Paper } from "@mui/material";
import firebase from "firebase";
import React, { useEffect, useContext, useState } from "react";
import "../../App.css";
import { UserContext } from "../../context/UserContext";
import Loader from "../../components/Loader/Loader";
import axios from "axios";

function StripeCheckout() {
  const userData = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.location.search.includes("fromPayment")) {
      checkStripeCustomer();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkStripeCustomer = () => {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(
            `${
              process.env.REACT_APP_ENDPOINT
            }/get-stripe-customer-by-email/?idToken=${idToken}&email=${
              userData.userData.email
            }&uid=${firebase.auth().currentUser.uid}`
          )
          .then(res => {
            if (res.data.code !== 200) {
              alert(
                "Error getting Stripe customer (1). Please contact support"
              );
            }
          })
          .catch(function () {
            alert("Error getting Stripe Customer (2). Please contact support");
          });
      })
      .catch(function () {
        alert("Authentication Error. Please contact support");
      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
          <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
            <h3>Payment Information</h3>
          </Paper>
          <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
            <p>
              Thanks for signing up! To start your 35 day Free Trail, please
              enter your payment details below. <br />
              <br />
              <b>You can cancel at any time.</b>
              <br />
              <br />
              <b>
                Please make sure you use the same email address (
                {userData.userData.email}) when completing the payment info.
              </b>
            </p>
            <div style={{ marginBottom: 20 }}>
              <button
                variant="contained"
                className="action-button"
                onClick={() => {
                  const url =
                    process.env.REACT_APP_PROJECT_ENV === "production"
                      ? `https://buy.stripe.com/cN2aGGbVealegMg7su?prefilled_email=${userData.userData.email}`
                      : `https://buy.stripe.com/test_eVa29YaG766H2VW8wx?prefilled_email=${userData.userData.email}`;

                  window.location.replace(url);
                }}
              >
                Add Payment Info
              </button>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
}

export default StripeCheckout;
