import React from "react";
import "./StandardMenuItem.css";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddBox from "@mui/icons-material/AddBox";

function StandardMenuItem(props) {
  const { name, icon, onClick, active, disabled } = props;
  return (
    <button
      disabled={disabled}
      onClick={() => {
        onClick();
      }}
      className="menu-item-container"
      style={{ backgroundColor: active ? "#455560" : null }}
    >
      {icon === "wallet" && (
        <AccountBalanceWalletIcon className="menu-item-icon" />
      )}
      {icon === "plus" && <AddBox className="menu-item-icon" />}
      <p className="menu-item-text">{name}</p>
    </button>
  );
}

export default StandardMenuItem;

