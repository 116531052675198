import Paper from "@mui/material/Paper";
import firebase from "firebase";
import React, { useState } from "react";
import "../../App.css";
import TextField from "../../components/TextField/TextField";
import * as API from "../../api";
import "./Accounts.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DeleteIcon from "@mui/icons-material/Delete";

function LinkBankAccounts() {
  const [loading, setLoading] = useState(false);
  const [accountsList, setAccountsList] = useState([]);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [startingBalance, setStartingBalance] = useState("");
  const [error, setError] = useState(null);

  const saveAccountInfo = () => {
    setLoading(true);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        API.saveAccounts({
          idToken: idToken,
          uid: firebase.auth().currentUser.uid,
          accounts: accountsList,
          bank: "manual",
          firstTime: true
        })
          .then(result => {
            setLoading(false);
            if (result.code === 200) {
              firebase
                .firestore()
                .collection("users")
                .doc(firebase.auth().currentUser.uid)
                .update({
                  accountsLinked: true
                });
            } else {
              alert(result.message);
            }
          })
          .catch(err => {
            setLoading(false);
            alert(err.message);
          });
      })
      .catch(err => {
        setLoading(false);
        alert("There has been an error, please try again.");
      });
  };

  const handleClose = () => {
    setStartingBalance("");
    setAccountName("");
    setError(null);
    setShowAddAccount(false);
  };

  const addAccountToList = () => {
    setError(null);
    setLoading(true);
    if (accountName.length < 3) {
      setError("Account Name needs to be at least 3 Characters");
      setLoading(false);
      return;
    }

    if (startingBalance.length === 0) {
      setError("Please add a starting balance.");
      setLoading(false);
      return;
    }

    var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
    const isValid = regexp.test(startingBalance);

    if (!isValid) {
      setError(
        "Starting balance is invalid. Please only add two numbers after the decimal place."
      );
      setLoading(false);
      return;
    } else {
      // Add the account to the list.
      setTimeout(() => {
        const accountListArray = accountsList;
        accountListArray.push({
          accountName: accountName,
          availableBalance: parseInt(startingBalance).toFixed(2)
        });
        setAccountsList(accountListArray);
        setLoading(false);
        setShowAddAccount(false);
      }, 1000);
    }
  };

  const removeAccountFromList = item => {
    let accountListArray = accountsList.filter(
      accountItem => accountItem.accountName !== item.accountName
    );

    setAccountsList(accountListArray);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", padding: 20 }}>
        <Paper style={{ marginBottom: 10, paddingLeft: 10 }}>
          <h3>Add Your Accounts</h3>
          <div style={{ marginTop: 20 }}>
            <p>
              Add as many accounts as you would like. We recommend adding all
              the accounts that form part of your monthly budget.
              <br />
              <br />
              <b>
                All your data is encrypted, so even if we wanted to see your
                transactions and balances, we couldn't.
              </b>
            </p>
          </div>
        </Paper>
        <Paper style={{ marginBottom: 10, padding: 10 }}>
          {accountsList.length === 0 ? (
            <div>
              <p>Click the button below to add your first account.</p>
            </div>
          ) : (
            <div>
              <List style={{ width: 400, marginVertical: 10 }}>
                {accountsList.map(item => {
                  return (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          onClick={() => {
                            removeAccountFromList(item);
                          }}
                          edge="end"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <AccountBalanceWalletIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.accountName}
                        secondary={`R ${parseInt(item.availableBalance).toFixed(
                          2
                        )}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          )}

          <div style-={{ display: "flex", flexDirection: "row" }}>
            <button
              style={{
                backgroundColor: loading ? "#647275" : null
              }}
              disabled={loading}
              variant="contained"
              className="action-button"
              onClick={() => {
                setStartingBalance("");
                setAccountName("");
                setShowAddAccount(true);
              }}
            >
              {accountsList.length === 0
                ? "Add Account"
                : "Add Another Account"}
            </button>
            {accountsList.length > 0 && (
              <button
                style={{
                  backgroundColor: loading ? "#647275" : null
                }}
                disabled={loading}
                variant="contained"
                className="action-button"
                onClick={() => {
                  saveAccountInfo();
                }}
              >
                {loading ? "Loading..." : "Save Accounts"}
              </button>
            )}
          </div>
          <Dialog
            open={showAddAccount}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              <h3>Add Account</h3>
            </DialogTitle>
            <DialogContent
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <TextField
                onChange={e => {
                  setAccountName(e.target.value);
                }}
                fullWidth
                label="Account Name"
                type="text"
              />
              <TextField
                onChange={e => {
                  setStartingBalance(e.target.value);
                }}
                fullWidth
                label="Starting Balance"
                type="number"
              />
              {error ? (
                <div style={{ paddingLeft: 10 }}>
                  <p className="error-text">{error}</p>
                </div>
              ) : null}
            </DialogContent>
            <DialogActions style={{ marginBottom: 20 }}>
              <button
                className="outline-action-button"
                disabled={loading}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                className="outline-action-button"
                disabled={loading}
                onClick={() => {
                  addAccountToList();
                }}
              >
                {loading ? "Loading..." : "Confirm"}
              </button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </div>
  );
}

export default LinkBankAccounts;
