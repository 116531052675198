import React from "react";
import "./AccountMenuItem.css";

function AccountMenuItem(props) {
  const { account, onClick, active } = props;
  return (
    <button
      onClick={onClick}
      className="account-menu-item-container"
      style={{ backgroundColor: active ? "#455560" : null }}
    >
      <div style={{ flex: 1, width: 180 }}>
        <p className="account-name">{account.accountName}</p>
      </div>
      <div>
        <p className="account-balance">
          {Number(account.availableBalance).toLocaleString("en")}
        </p>
      </div>
    </button>
  );
}

export default AccountMenuItem;
