import React, { useState, useEffect } from "react";
import CategorySelector from "../../../components/CategorySelector/CategorySelector";
import IncomeCategorySelector from "../../../components/IncomeCategorySelector/IncomeCategorySelector";
import MemoInput from "../../../components/MemoInput/MemoInput";
import "./AccountView.css";
import moment from "moment";

function TransactionItem(props) {
  const { item, budgetAmountMonth } = props;
  const [loading, setLoading] = useState(false);
  const [transactionItem, setTransactionItem] = useState(item);
  const [reconciled, setReconciled] = useState(false);

  const checkIsNegative = number => {
    return parseFloat(number) < 0;
  };

  useEffect(() => {
    if (transactionItem.userCategoryId) {
      setReconciled(true);
    } else {
      setReconciled(false);
    }
  }, [transactionItem]);

  const updateTransactionCategoryId = userCategoryId => {
    const updatedItem = transactionItem;
    updatedItem.userCategoryId = userCategoryId;
    if (typeof userCategoryId !== "string") {
      updatedItem.isSplit = true;
    } else {
      updatedItem.isSplit = false;
    }
    setReconciled(true);
    setLoading(false);
    setTransactionItem(updatedItem);
  };

  const hasCategoryId = item => {
    if (item && item.userCategoryId) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        backgroundColor: !reconciled ? "#f2f2f2" : ""
      }}
      className="account-item-container"
    >
      <p style={{ paddingLeft: 10 }} className="account-item-text">
        {loading ? (
          <div style={{ width: 100, textAlign: "center" }}>
            <div class="lds-dual-ring"></div>
          </div>
        ) : (
          `${moment.unix(String(item.sortingDate)).format("YYYY-MM-DD")}`
        )}
      </p>
      <div className="account-category-selector-container">
        {checkIsNegative(item.amount) ? (
          <div>
            <CategorySelector
              updateTransactionCategoryId={updateTransactionCategoryId}
              transaction={transactionItem}
              budgetAmountMonth={budgetAmountMonth}
              setLoading={setLoading}
            />
          </div>
        ) : (
          <div>
            <IncomeCategorySelector
              updateTransactionCategoryId={updateTransactionCategoryId}
              transaction={transactionItem}
              setLoading={setLoading}
            />
          </div>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <MemoInput
          memo={item.userDescription}
          transactionId={item.id}
          loading={loading}
          setLoading={val => {
            setLoading(val);
          }}
        />
      </div>
      <p style={{ fontWeight: "normal" }} className="account-item-text">
        {checkIsNegative(item.amount)
          ? `R ${parseFloat(item.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`
          : null}
      </p>
      <p style={{ fontWeight: "normal" }} className="account-item-text">
        {checkIsNegative(item.amount)
          ? null
          : `R ${String(parseFloat(item.amount).toFixed(2))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
      </p>
      <div style={{ width: 80 }}>
        {!reconciled && !loading && <div class="account-item-unreconciled" />}
      </div>
    </div>
  );
}

export default TransactionItem;
