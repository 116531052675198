import React from "react";
import "../App.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function Confirmation(props) {
  const {
    open,
    title,
    warning,
    loading,
    cancel,
    confirm,
    cta,
    error,
    hideCancel
  } = props;

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <p>{warning}</p>
          {error.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <p style={{ color: "#ff0000", fontWeight: "bold" }}>{error}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {!hideCancel && (
            <button
              className="outline-action-button"
              disabled={loading}
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </button>
          )}
          <button
            className="action-button"
            disabled={loading}
            onClick={() => {
              confirm();
            }}
            color="default"
            autoFocus
          >
            {loading ? "Loading.." : cta}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Confirmation;
