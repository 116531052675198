import firebase from "firebase";
import React, { useEffect, useState, useContext } from "react";
import "../../../App.css";
import axios from "axios";
import AccountViewHeading from "./AccountViewHeading";
import Loader from "../../../components/Loader/Loader";
import TransactionItem from "./TransactionItem";
import TransactionUpload from "./TransactionUpload";
import { UserContext } from "../../../context/UserContext";
import { BudgetContext } from "../../../context/BudgetContext";

import moment from "moment";
import { useLocation } from "react-router-dom";
import { Dialog } from "@mui/material";

function AccountView(props) {
  const { budgetAmountMonth, showMenu } = props;
  const [transactionsList, setTransactionsList] = useState([]);
  const [thisAccountDateLinked, setThisAccountDateLinked] = useState(null);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [uploadTransactions, setUploadTransactions] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);

  const userData = useContext(UserContext);
  const budgetData = useContext(BudgetContext);
  const location = useLocation();

  useEffect(() => {
    if (userData.userData.email && budgetData.accounts.length > 0) {
      getTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.key,
    budgetAmountMonth,
    userData.userData.email,
    budgetData.accounts
  ]);

  const getTransactions = () => {
    const accountId = window.location.pathname.split("/")[2];

    const accountInstance = budgetData.accounts?.find(
      element => element.id === accountId
    );

    console.log(budgetData.accounts);
    
    setAccountInfo(accountInstance);

    setThisAccountDateLinked(accountInstance.dateAccountLinked);

    setLoadingTransactions(true);
    const uid = firebase.auth().currentUser.uid;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        axios
          .get(
            `${
              process.env.REACT_APP_ENDPOINT
            }/get-user-transactions/?idToken=${idToken}&uid=${uid}&budgetAmountMonth=${budgetAmountMonth}&accountId=${accountId}&dateAccountLinked=${parseInt(
              accountInstance.dateAccountLinked
            )}`
          )
          .then(res => {
            if (res.data.code !== 200) {
              setLoadingTransactions(false);
              alert("Error getting transactions (1). Please contact support");
            } else {
              setTransactionsList(res.data.data);
              setLoadingTransactions(false);
            }
          })
          .catch(function (err) {
            setLoadingTransactions(false);
            alert("Error getting transactions (2). Please contact support");
          });
      })
      .catch(function () {
        setLoadingTransactions(false);
        alert("Authentication Error. Please contact support");
      });
  };

  return (
    <div
      style={{ marginLeft: showMenu ? "260px" : 2 }}
      className="standard-page-container"
    >
      {/* Top header text */}
      <div style={{ width: "100%" }}>
        {!loadingTransactions && (
          <div
            style={{
              paddingTop: "20px",
              position: "fixed",
              backgroundColor: "#ffffff",
              width: showMenu ? window.innerWidth - 260 : window.innerWidth,
              zIndex: 99
            }}
          >
            <AccountViewHeading />
          </div>
        )}
        <div style={{ width: "100%", paddingTop: "80px" }}>
          {transactionsList &&
            !loadingTransactions &&
            transactionsList.map((item, index) => {
              return (
                <TransactionItem
                  item={item}
                  key={index}
                  budgetAmountMonth={budgetAmountMonth}
                />
              );
            })}
          {loadingTransactions && <Loader />}
          {!loadingTransactions && transactionsList.length === 0 && (
            <div
              style={{
                width: "100%",
                height: 80,
                textAlign: "center",
                paddingTop: 50,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <div>
                {moment
                  .unix(parseInt(thisAccountDateLinked))
                  .format("YYYYMMDD") === moment().format("YYYYMMDD") && (
                  <p style={{ width: "100%", textAlign: "center" }}>
                    You linked your account today, so any transactions you
                    upload won't be shown yet.
                    <br />
                    <br /> Family Budget only starts tracking transactions from
                    the day you start your budget with us. We'll remind you in
                    the next few days to start importing your transactions.
                  </p>
                )}
              </div>
              <div>
                <p>There are no transactions to display for this month.</p>
                <button
                  style={{
                    backgroundColor: "#647275"
                  }}
                  variant="contained"
                  className="action-button"
                  onClick={() => {
                    setUploadTransactions(true);
                  }}
                >
                  Upload Transactions
                </button>
              </div>
            </div>
          )}
        </div>
        {transactionsList.length > 0 && (
          <div style={{ position: "fixed", bottom: 20, right: 20 }}>
            <button
              style={{
                backgroundColor: "#647275"
              }}
              variant="contained"
              className="action-button"
              onClick={() => {
                setUploadTransactions(true);
              }}
            >
              Upload Transactions
            </button>
          </div>
        )}
      </div>
      <div style={{ height: 20 }}></div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setUploadTransactions(false);
        }}
        open={uploadTransactions}
      >
        <TransactionUpload
          accountInfo={accountInfo}
          onClose={() => {
            setUploadTransactions(false);
          }}
          getTransactions={() => {
            setUploadTransactions(false);
            getTransactions();
          }}
        />
      </Dialog>
    </div>
  );
}

export default AccountView;
