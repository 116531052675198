import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TextField from "../../components/TextField/TextField";
import firebase from "firebase/app";
import "../../App.css";
import "./Auth.css";
import Dialog from "@mui/material/Dialog";

function Login(props) {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPasswordModal, showResetPasswordModal] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] =
    useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resettingPassword, setResettingPassword] = useState(false);

  const login = async () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        setLoading(false);
        setError(error.message);
      });
  };

  const resetPassword = () => {
    setResettingPassword(true);
    setResetPasswordError("");
    firebase
      .auth()
      .sendPasswordResetEmail(resetEmail)
      .then(function () {
        setResettingPassword(false);
        setShowResetPasswordSuccess(true);
      })
      .catch(function (error) {
        setResetPasswordError(error.message);
        setResettingPassword(false);
      });
  };

  const logKey = e => {
    if (e.code === "Enter") {
      login();
    }
  };

  return (
    <div>
      <Paper className="login-paper">
        <div
          onKeyDown={e => {
            logKey(e);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 10
          }}
        >
          <img
            style={{
              width: "90%",
              backgroundColor: "#455560",
              padding: 15,
              borderRadius: 15,
              marginBottom: 30
            }}
            src={require("../../assets/navbar-logo.png")}
            alt="Logo"
          />
          <h2 style={{ textAlign: "center" }}>Hi, Welcome Back!</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            Enter your credentials to continue
          </p>
          <TextField
            type="text"
            label="Email Address"
            fullWidth={true}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            type="password"
            label="Password"
            fullWidth={true}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <div
            className="hyperlink-text"
            onClick={() => {
              showResetPasswordModal(true);
              setShowResetPasswordSuccess(false);
            }}
          >
            <p>Forgot your password?</p>
          </div>
          <button
            disabled={loading}
            variant="contained"
            className="full-action-button"
            onClick={() => {
              login();
            }}
          >
            {loading ? "Loading...." : "Login"}
          </button>
          <p className="error-text">{error}</p>
          <div className="divider" />
          <div
            onClick={() => {
              props.switchToRegister();
            }}
          >
            <p className="large-hyperlink-text">I need an account</p>
          </div>
        </div>
      </Paper>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={resetPasswordModal}
        aria-labelledby="max-width-dialog-title"
      >
        {showResetPasswordSuccess ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h2>Success</h2>
            <p className="subheading" style={{ textAlign: "center" }}>
              Please check your email for a link to reset your password.
            </p>
            <button
              disabled={resettingPassword}
              onClick={() => {
                showResetPasswordModal(false);
              }}
              className="dialog-button"
            >
              Close
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h2>Reset Password</h2>
            <TextField
              type="text"
              label="Enter your email address"
              fullWidth={true}
              value={resetEmail}
              onChange={e => {
                setResetEmail(e.target.value);
              }}
            />
            <p className="error-text">{resetPasswordError}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: 100,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <button
                disabled={resettingPassword}
                onClick={() => {
                  showResetPasswordModal(false);
                }}
                className="dialog-button"
              >
                Cancel
              </button>
              <button
                disabled={resetEmail.length < 4 || resettingPassword}
                onClick={() => {
                  resetPassword();
                }}
                className="dialog-button"
              >
                {resettingPassword ? "Loading...." : "Submit"}
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default Login;
