import React, { useEffect, useState } from "react";
import "./CategoryList.css";
import AmountInput from "../../../components/AmountInput/AmountInput";
import firebase from "firebase";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditCategoryModal from "./EditCategoryModal";

function ChildCategoryItem(props) {
  const { item, budgetAmountMonth } = props;
  const [available, setAvailable] = useState(0);
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);

  useEffect(() => {
    let budgeted = 0;
    if (item.budgeted && item.budgeted[budgetAmountMonth]) {
      budgeted = parseFloat(item.budgeted[budgetAmountMonth]);
    }
    let spentThisMonth = 0;
    if (
      item.cumulativeTransactionAmounts &&
      item.cumulativeTransactionAmounts[budgetAmountMonth]
    ) {
      spentThisMonth = parseFloat(
        item.cumulativeTransactionAmounts[budgetAmountMonth]
      );
    }

    var availableAmount = budgeted + spentThisMonth;
    setAvailable(availableAmount.toFixed(2));
  }, [item, budgetAmountMonth]);

  const save = value => {
    firebase
      .firestore()
      .collection("categories")
      .doc(item.id)
      .update({
        [`budgeted.${budgetAmountMonth}`]:
          String(value).indexOf(".") >= 0 ? value : `${value}.00`
      });
  };

  const formatCell = number => {
    if (parseFloat(number) < 0) {
      return "negative-amount";
    } else if (parseFloat(number) > 0) {
      return "positive-amount";
    } else {
      return;
    }
  };

  return (
    <div className="child-category-item">
      <p style={{ flex: 1, paddingLeft: 10 }} className="child-category-text">
        {item.name}
      </p>
      {/* Budget Input */}
      <AmountInput
        reference={props.item.id}
        budgetedAmount={
          item.budgeted && item.budgeted[budgetAmountMonth]
            ? item.budgeted[budgetAmountMonth]
            : "0.00"
        }
        save={save}
      />
      <p className="child-category-text currency">
        {item.cumulativeTransactionAmounts &&
        item.cumulativeTransactionAmounts[budgetAmountMonth]
          ? parseFloat(
              item.cumulativeTransactionAmounts[budgetAmountMonth]
            ).toFixed(2)
          : "0.00"}
      </p>
      <p className={`child-category-text currency ${formatCell(available)}`}>
        {available}
      </p>
      <div
        onClick={() => {
          setEditCategoryOpen(true);
        }}
        className="child-category-item-button"
      >
        <MoreHorizIcon style={{ color: "#416b75" }} />
      </div>
      {editCategoryOpen && (
        <EditCategoryModal
          open={editCategoryOpen}
          onClose={() => {
            setEditCategoryOpen(false);
          }}
          category={item}
        />
      )}
    </div>
  );
}

export default ChildCategoryItem;
