import axios from "axios";

const apiInstance = axios.create({
  baseURL:
    process.env.REACT_APP_PROJECT_ENV === "production"
      ? process.env.REACT_APP_PROD_ENDPOINT
      : process.env.REACT_APP_DEV_ENDPOINT,
  timeout: 30000
});

const updateAccountInfo = async (params = {}) => {
  console.log(params);
  return apiInstance
    .get("/update-account-info", {
      params: {
        accountName: params.accountName,
        availableBalance: params.availableBalance,
        accountId: params.accountId,
        uid: params.uid,
        idToken: params.idToken
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const getAccountsForOnboarding = async (params = {}) => {
  return apiInstance
    .get("/get-accounts-for-onboarding", {
      params: {
        idToken: params.idToken,
        uid: params.uid,
        username: params.username,
        password: params.password,
        bank: params.bank
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const saveAccounts = async (params = {}) => {
  return apiInstance
    .get("/save-new-accounts", {
      params: {
        idToken: params.idToken,
        uid: params.uid,
        accounts: params.accounts,
        bank: params.bank,
        firstTime: params.firstTime
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const saveNewAccount = async (params = {}) => {
  return apiInstance
    .get("/save-new-account", {
      params: {
        idToken: params.idToken,
        uid: params.uid,
        accountName: params.accountName,
        startingBalance: params.startingBalance,
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const updateIncomeCategory = async (params = {}) => {
  return apiInstance
    .get("/update-income-category", {
      params: {
        idToken: params.idToken,
        uid: params.uid,
        transactionId: params.transactionId,
        transactionAmount: params.transactionAmount,
        descriptionValue: params.descriptionValue,
        mustBeRemovedFromAvailable: params.mustBeRemovedFromAvailable
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const updateLogins = async (params = {}) => {
  return apiInstance
    .get("/update-logins", {
      params: {
        id: params.id,
        uid: params.uid,
        idToken: params.idToken,
        username: params.username,
        password: params.password
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const saveParentCategoryName = async (params = {}) => {
  return apiInstance
    .get("/update-parent-category-name", {
      params: {
        value: params.value,
        itemId: params.itemId,
        idToken: params.idToken
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

const updateSplitCategory = async (params = {}) => {
  return apiInstance
    .get("/update-split-category", {
      params: {
        idToken: params.idToken,
        selectedCategories: params.selectedCategories,
        transactionId: params.transactionId,
        budgetAmountMonth: params.budgetAmountMonth,
        transactionAmount: params.transactionAmount
      }
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err.message;
    });
};

export {
  updateAccountInfo,
  getAccountsForOnboarding,
  saveAccounts,
  updateIncomeCategory,
  updateLogins,
  saveParentCategoryName,
  updateSplitCategory,
  saveNewAccount
};
