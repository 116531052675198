import React, { useContext, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BudgetContext } from "../../context/BudgetContext";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import * as API from "../../api";
import firebase from "firebase";

function SplitCategory(props) {
  const {
    memo,
    open,
    amount,
    budgetAmountMonth,
    transactionId,
    transactionAmount,
    userCategoryId
  } = props;
  const [loading, setLoading] = useState(false);
  const budgetData = useContext(BudgetContext);
  const [calculatedAmount, setCalculatedAmount] = useState(amount);
  const [selectedCategories, setSelectedCategories] = useState([
    { name: "", id: "", amount: "" },
    { name: "", id: "", amount: "" }
  ]);

  useEffect(() => {
    if (userCategoryId && userCategoryId[0]?.name) {
      setSelectedCategories(userCategoryId);
      let calculatedAmountTemp = 0;
      userCategoryId.forEach((item, index) => {
        if (parseFloat(item["amount"]) >= 0) {
          calculatedAmountTemp =
            calculatedAmountTemp + parseFloat(item["amount"]);

          let finalAmount =
            parseFloat(amount) + parseFloat(calculatedAmountTemp);
          setCalculatedAmount(finalAmount.toFixed(2));
        }
      });
    }
  }, []);

  // Handle reallocating a transaction if it already has a category
  // Perhaps just reset and reallocate no matter what the user does.
  // Undo split and back to single category.

  const confirm = () => {
    setLoading(true);
    if (parseFloat(calculatedAmount) === 0) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          API.updateSplitCategory({
            idToken: idToken,
            selectedCategories: JSON.stringify(selectedCategories),
            transactionId: transactionId,
            budgetAmountMonth: budgetAmountMonth,
            transactionAmount: transactionAmount
          }).then(result => {
            if (result.code === 200) {
              props.updateTransactionCategoryId(selectedCategories);
              setLoading(false);
              setSelectedCategories([
                { name: "", id: "", amount: "" },
                { name: "", id: "", amount: "" }
              ]);
              props.close();
            } else {
              setLoading(false);
              alert(
                "There has been an error, please try again or contact support."
              );
            }
          });
        });
    } else {
      setLoading(false);
      alert(
        "The sum of all the categories does not equal the transaction amount."
      );
    }
  };

  const handleChangeCategory = (event, index) => {
    const selectedCategoryArray = selectedCategories;

    selectedCategoryArray[index].name = budgetData.childCategories.filter(
      item => item.id === event.target.value
    )[0].name;
    selectedCategoryArray[index].id = event.target.value;

    setSelectedCategories([...selectedCategoryArray]);
  };

  const handleChangeAmount = (passedAmount, index) => {
    const selectedCategoryArray = selectedCategories;
    // Amount from the input
    selectedCategoryArray[index].amount = passedAmount;

    setSelectedCategories(selectedCategoryArray);

    let calculatedAmountTemp = 0;

    selectedCategoryArray.forEach((item, index) => {
      if (parseFloat(item["amount"]) >= 0) {
        calculatedAmountTemp =
          calculatedAmountTemp + parseFloat(item["amount"]);

        let finalAmount = parseFloat(amount) + parseFloat(calculatedAmountTemp);
        setCalculatedAmount(finalAmount.toFixed(2));
      }
    });
  };

  const addSplit = () => {
    const selectedCategoryArray = selectedCategories;

    setSelectedCategories([
      ...selectedCategoryArray,
      { name: "", id: "", amount: "" }
    ]);
  };

  return (
    <Dialog
      onClose={() => {
        setSelectedCategories([
          { name: "", id: "", amount: "" },
          { name: "", id: "", amount: "" }
        ]);
      }}
      maxWidth="sm"
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Split Category for {memo}
      </DialogTitle>
      <DialogContent style={{ padding: 5, marginRight: 10, marginLeft: 10 }}>
        <p>Select all the categories that apply.</p>
        {selectedCategories.map((selectedItemValue, index) => {
          return (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <FormControl style={{ marginTop: 10 }} fullWidth>
                    <InputLabel style={{ backgroundColor: "#fff" }}>
                      Category
                    </InputLabel>
                    <Select
                      value={selectedCategories[index].id}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={value => handleChangeCategory(value, index)}
                    >
                      {budgetData.childCategories.map(item => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ marginTop: 10 }} fullWidth>
                    <TextField
                      value={selectedItemValue.amount}
                      type="number"
                      label="Amount"
                      placeholder="Value"
                      disableClearable
                      onChange={e => {
                        handleChangeAmount(e.target.value, index);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <p>Outstanding amount to allocate:</p>
          </Grid>
          <Grid
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
              width: "100%"
            }}
            item
            xs={4}
          >
            <p style={{ marginRight: 10 }}>R {calculatedAmount}</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} />
          <Grid
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              display: "flex",
              width: "100%"
            }}
            item
            xs={4}
          >
            <button
              onClick={() => {
                addSplit();
              }}
              style={{ backgroundColor: "#fff", border: "none" }}
            >
              <p class="hyperlink-text" style={{ marginRight: 10 }}>
                + Add Split
              </p>
            </button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          className="outline-action-button"
          disabled={loading}
          onClick={() => {
            setSelectedCategories([
              { name: "", id: "", amount: "" },
              { name: "", id: "", amount: "" }
            ]);
            props.close();
          }}
        >
          Cancel
        </button>
        <button
          className="action-button"
          disabled={loading}
          onClick={() => {
            confirm();
          }}
          color="default"
          autoFocus
        >
          {loading ? "Loading..." : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default SplitCategory;
