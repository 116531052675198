import React, { useContext, useState } from "react";
import "./SideMenu.css";
import { UserContext } from "../../context/UserContext";
import { BudgetContext } from "../../context/BudgetContext";
import AccountMenuItem from "./AccountMenuItem/AccountMenuItem";
import StandardMenuItem from "./StandardMenuItem/StandardMenuItem";
import { useHistory } from "react-router-dom";
import AddNewAccount from "../../screens/Accounts/AddNewAccount";
import Dialog from "@mui/material/Dialog";

function SideMenu() {
  const userData = useContext(UserContext);
  const budgetData = useContext(BudgetContext);
  const history = useHistory();
  const [showAddNewAccount, setShowAddNewAccount] = useState(false);

  return (
    <div>
      {userData.userData.onboardingComplete ? (
        <div className="side-menu-container">
          <StandardMenuItem
            name="Budget"
            icon="wallet"
            onClick={() => {
              history.push(`/`);
              // Navigate here..
            }}
            active={window.location.pathname === "/"}
          />
          {/* <StandardMenuItem
            name="All Accounts"
            icon="wallet"
            onClick={() => {
              history.push(`/accounts`);
            }}
            active={window.location.pathname.includes("accounts")}
          /> */}
          <div className="menu-item-subheading-container">
            <p className="menu-item-subheading">Your Accounts</p>
          </div>
          {budgetData.loadingAccounts ? (
            <p className="enu-item-subheading">Loading...</p>
          ) : (
            <div className="account-list-container">
              {budgetData.accounts.map(account => {
                return (
                  <AccountMenuItem
                    account={account}
                    key={account.id}
                    active={window.location.pathname.includes(
                      String(account.id)
                    )}
                    onClick={() => {
                      history.push(`/account/${account.id}`);
                    }}
                  />
                );
              })}
              <StandardMenuItem
                name="Add New Account"
                icon="plus"
                onClick={() => {
                  setShowAddNewAccount(true);
                }}
                active={false}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="side-menu-container">
          <StandardMenuItem
            name="Budget"
            icon="wallet"
            onClick={() => {
              // Navigate here..
            }}
            active={false}
            disabled={true}
          />
        </div>
      )}
      <Dialog
        open={showAddNewAccount}
        fullWidth
        maxWidth={"md"}
        onClose={() => {
          setShowAddNewAccount(false);
        }}
      >
        <AddNewAccount
          close={() => {
            setShowAddNewAccount(false);
          }}
        />
      </Dialog>
    </div>
  );
}

export default SideMenu;
