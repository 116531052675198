import firebase from "firebase";
import React, { useState, createContext, useEffect } from "react";

export const UserContext = createContext();

const UserContextProvider = props => {
  const [userData, setUserData] = useState(false);
  const user = firebase.auth().currentUser;

  useEffect(() => {
    checkUserExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUserExists = () => {
    firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .onSnapshot(doc => {
        if (!doc.exists) {
          firebase.firestore().collection("users").doc(user.uid).set(
            {
              email: user.email,
              onboardingComplete: false
            },
            { merge: true }
          );
        } else {
          setUserData(doc.data());
        }
      });
  };

  return (
    <UserContext.Provider value={{ userData }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
