import React, { useContext } from "react";
import "../../App.css";
import { UserContext } from "../../context/UserContext";
import StripeCheckout from "./StripeCheckout";
import LinkBankAccounts from "./LinkBankAccounts";
import CollectDetails from "./CollectDetails";
import SuccessScreen from "./SuccessScreen";
import { Paper } from "@mui/material";

function LinkAccount() {
  const userData = useContext(UserContext);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ paddingTop: 20, paddingRight: 20, paddingLeft: 20 }}>
        <Paper style={{ padding: 10 }}>
          <div>
            <p>
              If you need any help, please follow along with our{" "}
              <a
                href="https://familybudget.co.za/2022/10/06/setting-up-your-profile/"
                target="_blank"
                without
                rel="noopener noreferrer"
                style={{ fontSize: 14, textDecoration: "underline" }}
                className="large-hyperlink-text"
              >
                Getting Started
              </a>{" "}
              article.
            </p>
          </div>
        </Paper>
      </div>
      {!userData.userData.paymentActive &&
        !userData.userData.accountsLinked &&
        !userData.userData.detailsCollected && <CollectDetails />}
      {!userData.userData.paymentActive &&
        !userData.userData.accountsLinked &&
        userData.userData.detailsCollected && <StripeCheckout />}
      {!userData.userData.accountsLinked &&
        userData.userData.paymentActive &&
        userData.userData.detailsCollected && <LinkBankAccounts />}
      {userData.userData.accountsLinked &&
        userData.userData.paymentActive &&
        userData.userData.detailsCollected && <SuccessScreen />}
    </div>
  );
}

export default LinkAccount;
