import React, { useState } from "react";
import Login from "./Login";
import Register from "./Register";
import "./Auth.css";

function AuthPage() {
  const [pageSate, setPageState] = useState("Login");
  return (
    <div className="login-container">
      {pageSate === "Login" && (
        <Login
          switchToRegister={() => {
            setPageState("Register");
          }}
        />
      )}
      {pageSate === "Register" && (
        <Register
          switchToLogin={() => {
            setPageState("Login");
          }}
        />
      )}
    </div>
  );
}

export default AuthPage;
