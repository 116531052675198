import React, { useState, useContext, useEffect } from "react";
import "./NavBar.css";
import { BudgetContext } from "../../context/BudgetContext";
import { UserContext } from "../../context/UserContext";

function BudgetAmounts(props) {
  const { budgetAmountMonth } = props;
  const [available, setAvailable] = useState(0.0);
  const budgetData = useContext(BudgetContext);
  const userData = useContext(UserContext);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    setUserInfo(userData.userData);
    getAvailable(userData.userData, budgetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, budgetData]);

  const getAvailable = (userValue, budgetValue) => {
    var tempAvailable = userValue.availableToBudget;
    setAvailable(tempAvailable);
    let alreadyBudgeted = 0;
    budgetValue.childCategories.forEach(item => {
      let budgetedAmount = item.budgeted;
      if (budgetedAmount) {
        let budgetedValues = Object.values(item.budgeted);
        budgetedValues = budgetedValues.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        alreadyBudgeted = alreadyBudgeted - budgetedValues;
      }
    });
    setAvailable(parseFloat(tempAvailable) + parseFloat(alreadyBudgeted));
  };

  useEffect(() => {
    if (budgetData) {
      let spent = 0;
      budgetData.childCategories.forEach(item => {
        spent =
          spent +
            parseInt(item.cumulativeTransactionAmounts[budgetAmountMonth]) *
              -1 || 0;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetData]);

  const checkIsNegative = number => {
    return parseFloat(number) < 0;
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div className="budget-amount-container">
        <div className="amount-descriptor-text">Available To Assign</div>
        <div
          className="amount-value-text"
          style={{
            color: checkIsNegative(available.toFixed(2))
              ? "#b22222"
              : "#1d1d1d",
            textAlign: "center"
          }}
        >
          {typeof userInfo.availableToBudget === "number"
            ? `R ${available.toFixed(2)}`
            : "..."}
        </div>
      </div>
    </div>
  );
}

export default BudgetAmounts;
