import React, { useState, useContext } from "react";
import "./AccountView.css";
import firebase from "firebase";
import Confirmation from "../../../components/Confirmation";
import { BudgetContext } from "../../../context/BudgetContext";

function TransactionUpload(props) {
  const { accountInfo, onClose, getTransactions } = props;
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const budgetData = useContext(BudgetContext);

  const handleSubmit = e => {
    e.preventDefault();
    const file = e.target[0]?.files[0];

    if (!file) return;
    setLoading(true);
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        var formdata = new FormData();
        formdata.append(
          "file",
          file,
          `${accountInfo.id}---${accountInfo.uid}.ofx`
        );
        formdata.append("token", idToken);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow"
        };

        const baseURL =
          process.env.REACT_APP_PROJECT_ENV === "production"
            ? process.env.REACT_APP_PROD_ENDPOINT
            : process.env.REACT_APP_DEV_ENDPOINT;

        fetch(`${baseURL}/ofx/upload`, requestOptions)
          .then(response => response.text())
          .then(result => {
            setLoading(false);
            const resultData = JSON.parse(result);
            setResultMessage(resultData.result.importMessage);
            if (resultData.code === 200) {
              setShowConfirmation(true);
            } else {
              alert(resultData.message);
            }
          })
          .catch(error => {
            setLoading(false);
            alert(error.message);
          });
      });
  };

  return (
    <div style={{ padding: 10 }}>
      <h2 style={{ width: "100%" }}>Import Into {accountInfo.accountName}</h2>
      <p>
        We currently only accept OFX files. These can be downloaded from your
        internet banking profile.
      </p>
      <form onSubmit={handleSubmit} className="form">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginBottom: 20 }}>
            <input accept=".ofx" type="file" />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <button
              disabled={loading}
              className="action-button"
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 10 }}
            >
              Close
            </button>
            <button disabled={loading} className="action-button" type="submit">
              {loading ? "Loading..." : "Upload"}
            </button>
          </div>
        </div>
      </form>
      <Confirmation
        hideCancel={true}
        open={showConfirmation}
        title={`Import Result`}
        warning={resultMessage}
        error={""}
        confirm={() => {
          setShowConfirmation(false);
          budgetData.getAccounts(true).then(() => {
            getTransactions();
          });
        }}
        cta="Ok"
      />
    </div>
  );
}

export default TransactionUpload;
