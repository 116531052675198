import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import SideMenu from "../components/SideMenu/SideMenu";
import AccountView from "./Budget/AccountView/AccountView";

export default function MainApp(props) {
  const { showMenu, budgetAmountMonth } = props;

  return (
    <Router>
      <div
        style={{ display: "flex", flexDirection: "row", paddingTop: "70px" }}
      >
        {showMenu && <SideMenu />}
        {/* Defines what displays when you're at what route */}
        <Switch>
          <Route exact path="/">
            <Dashboard
              showMenu={showMenu}
              budgetAmountMonth={budgetAmountMonth}
            />
          </Route>
          {/* <Route exact path="/all-accounts">
            <p>All Accounts</p>
          </Route> */}
          <Route exact path="/account/:id">
            <AccountView
              budgetAmountMonth={budgetAmountMonth}
              showMenu={showMenu}
            />
          </Route>
        </Switch>
        <div
          style={{
            position: "fixed",
            margin: 20,
            bottom: 0,
            backgroundColor: "#ffffff",
            padding: 10,
            borderRadius: 8,
            cursor: "pointer"
          }}
          onClick={() => {
            window.open(
              "https://familybudget.co.za/help/",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <p
            className="menu-item-text"
            style={{ textAlign: "center", color: "#455560" }}
          >
            Need help?
          </p>
        </div>
      </div>
    </Router>
  );
}
